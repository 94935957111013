import {
  AccountType,
  BankruptAge,
  BankruptType,
  BuyingProcess,
  CitizenshipType,
  CommercialLoanHolder,
  CommercialBusinessType,
  CommercialEntityType,
  CommercialPurchasePropertyType,
  CommercialTrends,
  ConstructionLoanType,
  DayOfWeek,
  DesiredLoanTerm,
  EmploymentOwnershipShare,
  EscrowUsage,
  LandingLoanType,
  LiabilityUsage,
  LivingSituation,
  MaritalStatus,
  MilitaryExperienceType,
  NameSuffix,
  PropertyType,
  PropertyUsage,
  PurchaseTimeline,
  Purpose,
  TimeOfDay,
  TitleHeld,
  VehiclePurchasePlan,
  YesOrNo,
  CreditBoosterAccountTypes,
  NumberOfStructures,
  EquipmentOrInventory,
  CommercialLoanTiming,
  NumberOfVehicles,
  NewOrUsed,
  BridgeOrCredit,
  NumberOfStories,
  ConstructionHomeType,
  ConstructionRoofingType,
  NumberOfUnits,
  CommercialBusinessOwnershipType,
  FormName,
  CommercialCollateralDescriptionType,
  CurrentLoanType,
} from 'app/models/options/enums';
import {
  EmploymentType,
  IncomeSource,
  LiabilityStatus,
  PersonalPropertyType,
  RefinancePurpose,
  RetirementAccountType,
  ReverseMortgagePropertyTitleHeld,
  ReverseMortgageLoanType,
  ReverseMortgageLoanPurpose,
  TaxType,
  VehicleType,
  Ethnicity,
  EthnicityHispanic,
  Race,
  RaceAsian,
  RaceIslander,
  Sex,
  HomeEquityLoanPurpose, ProductFamily,
} from '@lenderful/domain';
import moment from 'moment';

export interface FormOption<T> {
  value    : T;
  title    : string;
}

export const DECLARATION_NO_SHARE = 'NO_SHARE';
export const BUYING_PROCESS_OPTIONS: FormOption<BuyingProcess>[] = [
  { title: 'Signed Purchase Agreement', value: BuyingProcess.SIGNED },
  { title: 'Offer Pending', value: BuyingProcess.PENDING },
  { title: 'Shopping', value: BuyingProcess.SHOPPING },
  { title: 'Other', value: BuyingProcess.OTHER },
];
export const PREQUAL_BUYING_PROCESS_OPTIONS: FormOption<BuyingProcess>[] = [
  { title: 'I\'ve found a home – offer pending', value: BuyingProcess.PENDING },
  { title: 'I\'m shopping & considering a few homes', value: BuyingProcess.SHOPPING },
  { title: 'I\'ve just started thinking about buying', value: BuyingProcess.OTHER },
];
export const PURCHASE_TIMELINE_OPTIONS: FormOption<PurchaseTimeline>[] = [
  { title: '0-3 months', value: PurchaseTimeline.SHORT },
  { title: '3-6 months', value: PurchaseTimeline.MEDIUM },
  { title: '6+ months', value: PurchaseTimeline.LONG },
];
export const PROPERTY_USAGE_OPTIONS: FormOption<PropertyUsage>[] = [
  { title: 'Primary Home', value: PropertyUsage.PRIMARY },
  { title: 'Secondary Home', value: PropertyUsage.SECONDARY },
  { title: 'Investment Property', value: PropertyUsage.INVESTMENT },
];
export const REO_PROPERTY_USAGE_OPTIONS: FormOption<PropertyUsage>[] = [
  { title: 'Secondary Home', value: PropertyUsage.SECONDARY },
  { title: 'Investment Property', value: PropertyUsage.INVESTMENT },
];

export const PROPERTY_TYPE_OPTIONS: FormOption<PropertyType>[] = [
  { title: 'Single Family Home', value: PropertyType.SINGLE },
  { title: 'Condominium', value: PropertyType.CONDO },
  { title: 'Multi Family Home', value: PropertyType.MULTI },
  { title: 'Modular Home', value: PropertyType.MODULAR },
  { title: 'Manufactured Home on Foundation', value: PropertyType.MANUFACTURED_HOME_ON_FOUNDATION },
];
export const ESCROW_USAGE_OPTIONS: FormOption<EscrowUsage>[] = [
  { title: 'Yes', value: EscrowUsage.YES },
  { title: 'No', value: EscrowUsage.NO },
];
export const REFINANCE_REASON_OPTIONS: FormOption<RefinancePurpose>[] = [
  { title: 'Lower Monthly Payment', value: RefinancePurpose.MONTHLY },
  { title: 'Change Length of Loan', value: RefinancePurpose.TERMS },
  { title: 'Take Cash Out of Home', value: RefinancePurpose.CASHOUT },
  { title: 'Consolidate Debt', value: RefinancePurpose.CONSOLIDATE },
];
export const YES_OR_NO_OPTIONS: FormOption<YesOrNo>[] = [
  { title: 'Yes', value: YesOrNo.YES },
  { title: 'No', value: YesOrNo.NO },
];
export const MARITAL_STATUS_OPTIONS: FormOption<MaritalStatus>[] = [
  { title: 'Married', value: MaritalStatus.MARRIED },
  { title: 'Unmarried', value: MaritalStatus.UNMARRIED },
  { title: 'Separated', value: MaritalStatus.SEPARATED },
];
export const ACCOUNT_TYPE_PURCHASE_OPTIONS: FormOption<AccountType>[] = [
  { title: 'Proceeds from Sale of Home', value: AccountType.SALE_PROCEEDS },
  { title: 'Checking Account', value: AccountType.CHECKING },
  { title: 'Savings Account', value: AccountType.SAVINGS },
  { title: 'Retirement Account', value: AccountType.RETIREMENT },
  { title: 'Investment Account', value: AccountType.INVESTMENT },
  { title: 'Gift Funds', value: AccountType.GIFT },
  { title: 'Earnest Money', value: AccountType.EARNEST_MONEY },
  { title: 'Other', value: AccountType.OTHER },
];
export const ACCOUNT_TYPE_REFINANCE_OPTIONS: FormOption<AccountType>[] = [
  { title: 'Checking Account', value: AccountType.CHECKING },
  { title: 'Savings Account', value: AccountType.SAVINGS },
  { title: 'Retirement Account', value: AccountType.RETIREMENT },
  { title: 'Investment Account', value: AccountType.INVESTMENT },
  { title: 'Other', value: AccountType.OTHER },
];
export const COMMERCIAL_ACCOUNT_TYPE_PURCHASE_OPTIONS: FormOption<AccountType>[] = [
  { title: 'Checking Account', value: AccountType.CHECKING },
  { title: 'Savings Account', value: AccountType.SAVINGS },
  { title: 'Investment Account', value: AccountType.INVESTMENT },
  { title: 'Other', value: AccountType.OTHER },
];
export const LIVING_SITUATION_OPTIONS: FormOption<LivingSituation>[] = [
  { title: 'Homeowner', value: LivingSituation.OWN },
  { title: 'Renter', value: LivingSituation.RENT },
  { title: 'Living Rent Free', value: LivingSituation.NORENT },
];
export const COBORROWER_EMPLOYMENT_TYPE_OPTIONS: FormOption<EmploymentType>[] = [
  { title: 'Employed by a Company', value: EmploymentType.EMPLOYEE },
  { title: 'Self-Employed', value: EmploymentType.SELF },
  { title: 'Pension or Retirement', value: EmploymentType.RETIRED },
  { title: 'Not Employed', value: EmploymentType.NOT_EMPLOYED },
  { title: 'Other Income Source', value: EmploymentType.OTHER },
];
export const EMPLOYMENT_TYPE_OPTIONS: FormOption<EmploymentType>[] = COBORROWER_EMPLOYMENT_TYPE_OPTIONS.filter((option) => option.value !== EmploymentType.NOT_EMPLOYED);
export const INCOME_SOURCE_OPTIONS: FormOption<IncomeSource>[] = [
  { title: 'Self-Employed', value: IncomeSource.SELF },
  { title: 'Second Job', value: IncomeSource.SECOND },
  { title: 'Pension / Retirement', value: IncomeSource.RETIRED },
  { title: 'Social Security / Disability', value: IncomeSource.SOCIAL },
  { title: 'Child Support', value: IncomeSource.CHILD },
  { title: 'Alimony', value: IncomeSource.ALIMONY },
  { title: 'Military Entitlements', value: IncomeSource.MILITARY },
];
export const OTHER_INCOME_SOURCE_OPTIONS: FormOption<IncomeSource>[] = [
  { title: 'Social Security / Disability', value: IncomeSource.SOCIAL },
  { title: 'Child Support', value: IncomeSource.CHILD },
  { title: 'Alimony', value: IncomeSource.ALIMONY },
  { title: 'Military Entitlements', value: IncomeSource.MILITARY },
];
export const RETIREMENT_INCOME_SOURCE_OPTIONS: FormOption<IncomeSource>[] = [
  { title: 'Social Security / Disability', value: IncomeSource.SOCIAL },
  { title: 'Pension / Retirement', value: IncomeSource.RETIRED },
  { title: 'Second Job', value: IncomeSource.SECOND },
  { title: 'Self-Employed', value: IncomeSource.SELF },
  { title: 'Child Support', value: IncomeSource.CHILD },
  { title: 'Alimony', value: IncomeSource.ALIMONY },
  { title: 'Military Entitlements', value: IncomeSource.MILITARY },
];
export const BANKRUPT_TYPE_OPTIONS: FormOption<BankruptType>[] = [
  { title: 'Chapter 7', value: BankruptType.SEVEN },
  { title: 'Chapter 11', value: BankruptType.ELEVEN },
  { title: 'Chapter 12', value: BankruptType.TWELVE },
  { title: 'Chapter 13', value: BankruptType.THIRTEEN },
];
export const BANKRUPT_AGE_OPTIONS: FormOption<BankruptAge>[] = [
  { title: '1 Year', value: BankruptAge.ONE },
  { title: '2 Years', value: BankruptAge.TWO },
  { title: '3 Years', value: BankruptAge.THREE },
  { title: '4 Years', value: BankruptAge.FOUR },
  { title: '5 Years', value: BankruptAge.FIVE },
  { title: '6 Years', value: BankruptAge.SIX },
  { title: '7+ Years', value: BankruptAge.SEVEN },
];
export const TITLE_HELD_OPTIONS: FormOption<TitleHeld>[] = [
  { title: 'Solely by yourself', value: TitleHeld.SOLE },
  { title: 'Jointly with your spouse', value: TitleHeld.SPOUSE },
  { title: 'Jointly with another person', value: TitleHeld.OTHER },
];
export const ETHNICITY_OPTIONS: FormOption<Ethnicity>[] = [
  { title: 'I do not wish to share this information', value: DECLARATION_NO_SHARE as Ethnicity.NOSHARE },
  { title: 'Not Hispanic or Latino', value: Ethnicity.NOHISPANIC },
  { title: 'Hispanic or Latino', value: Ethnicity.HISPANIC },
];
export const ETHNICITY_HISPANIC_OPTIONS: FormOption<EthnicityHispanic>[] = [
  { title: 'Mexican', value: EthnicityHispanic.MEXICAN },
  { title: 'Puerto Rican', value: EthnicityHispanic.PUERTO },
  { title: 'Cuban', value: EthnicityHispanic.CUBAN },
  { title: 'Other Hispanic or Latino', value: EthnicityHispanic.OTHER },
];
export const RACE_OPTIONS: FormOption<Race>[] = [
  { title: 'I do not wish to share this information', value: DECLARATION_NO_SHARE as Race.NOSHARE },
  { title: 'American Indian or Alaska Native', value: Race.NATIVE },
  { title: 'Asian', value: Race.ASIAN },
  { title: 'Black or African American', value: Race.AFRICAN },
  { title: 'Native Hawaiian or Other Pacific Islander', value: Race.ISLANDER },
  { title: 'White', value: Race.WHITE },
];
export const RACE_ASIAN_OPTIONS: FormOption<RaceAsian>[] = [
  { title: 'Asian Indian', value: RaceAsian.INDIAN },
  { title: 'Chinese', value: RaceAsian.CHINESE },
  { title: 'Filipino', value: RaceAsian.FILIPINO },
  { title: 'Japanese', value: RaceAsian.JAPANESE },
  { title: 'Korean', value: RaceAsian.KOREAN },
  { title: 'Vietnamese', value: RaceAsian.VIET },
  { title: 'Other Asian', value: RaceAsian.OTHER },
];
export const RACE_ISLANDER_OPTIONS: FormOption<RaceIslander>[] = [
  { title: 'Native Hawaiian', value: RaceIslander.NATIVE },
  { title: 'Guamanian or Chamorro', value: RaceIslander.GUAM },
  { title: 'Samoan', value: RaceIslander.SAMOAN },
  { title: 'Other Pacific Islander', value: RaceIslander.OTHER },
];
export const SEX_OPTIONS: FormOption<Sex>[] = [
  { title: 'I do not wish to share this information', value: DECLARATION_NO_SHARE as Sex.NOSHARE },
  { title: 'Female', value: Sex.FEMALE },
  { title: 'Male', value: Sex.MALE },
];
export const PURPOSE_OPTIONS: FormOption<Purpose>[] = [
  { title: 'Start your Mortgage Application', value: Purpose.PURCHASE },
  { title: 'Get a Pre-Qualification Letter', value: Purpose.PREQUAL },
];
export const LANDING_LOAN_TYPE_OPTIONS: FormOption<LandingLoanType>[] = [
  { title: 'Home Purchase', value: LandingLoanType.PURCHASE },
  { title: 'Home Refinance', value: LandingLoanType.REFINANCE },
];
export const HOME_EQUITY_LOAN_PURPOSE_OPTIONS: FormOption<HomeEquityLoanPurpose>[] = [
  { title: 'Debt Consolidation', value: HomeEquityLoanPurpose.CONSOLIDATION },
  { title: 'Purchase Another Home', value: HomeEquityLoanPurpose.SECONDHOME },
  { title: 'Future Home Improvement', value: HomeEquityLoanPurpose.FUTURE_IMPROVEMENT },
  { title: 'Completed Home Improvement', value: HomeEquityLoanPurpose.COMPLETED_IMPROVEMENT },
  { title: 'Bridge Loan To Finance Real Estate', value: HomeEquityLoanPurpose.BRIDGE },
  { title: 'Business Use', value: HomeEquityLoanPurpose.BUSINESS },
  { title: 'Other', value: HomeEquityLoanPurpose.OTHER },
];
export const REAL_ESTATE_LIABILITY_OPTIONS: FormOption<LiabilityStatus>[] = [
  { title: 'Sell it', value: LiabilityStatus.SELL },
  { title: 'Continue to own it', value: LiabilityStatus.OWN },
  { title: 'Don\'t include this property', value: LiabilityStatus.EXCLUDE },
];
export const REAL_ESTATE_LIABILITY_USAGE: FormOption<LiabilityUsage>[] = [
  { title: 'This is my primary home', value: LiabilityUsage.PRIMARY_HOME },
  { title: 'This is another home I own', value: LiabilityUsage.OTHER_HOME },
  { title: 'This is not my property', value: LiabilityUsage.NOT_MY_HOME },
];
export const HOME_EQUITY_REO_LIABILITY_USAGE: FormOption<LiabilityUsage>[] = [
  { title: 'This is my primary home', value: LiabilityUsage.PRIMARY_HOME },
  { title: 'This is another home I own', value: LiabilityUsage.OTHER_HOME },
  { title: 'This is not my property', value: LiabilityUsage.NOT_MY_HOME },
  { title: 'This is the collateral home for the home equity loan', value: LiabilityUsage.COLLATERAL_HOME },
];
// These are days based on LMCU's hours of operation
export const DAY_OF_WEEK_OPTIONS: FormOption<DayOfWeek>[] = [
  { title: 'Any Day', value: DayOfWeek.ANY },
  { title: 'Monday', value: DayOfWeek.MONDAY },
  { title: 'Tuesday', value: DayOfWeek.TUESDAY },
  { title: 'Wednesday', value: DayOfWeek.WEDNESDAY },
  { title: 'Thursday', value: DayOfWeek.THURSDAY },
  { title: 'Friday', value: DayOfWeek.FRIDAY },
];
// These times come from LMCU's hours of operaiton
export const TIME_OF_DAY_OPTIONS: FormOption<TimeOfDay>[] = [
  { title: 'Any Time', value: TimeOfDay.ANY },
  { title: '9:00 am', value: TimeOfDay.NINE },
  { title: '10:00 am', value: TimeOfDay.TEN },
  { title: '11:00 am', value: TimeOfDay.ELEVEN },
  { title: '12:00 pm', value: TimeOfDay.TWELVE },
  { title: '1:00 pm', value: TimeOfDay.ONE },
  { title: '2:00 pm', value: TimeOfDay.TWO },
  { title: '3:00 pm', value: TimeOfDay.THREE },
  { title: '4:00 pm', value: TimeOfDay.FOUR },
];
export const STATE_OPTIONS: FormOption<string>[] = [
  { value: 'AL', title: 'Alabama' },
  { value: 'AK', title: 'Alaska' },
  { value: 'AZ', title: 'Arizona' },
  { value: 'AR', title: 'Arkansas' },
  { value: 'CA', title: 'California' },
  { value: 'CO', title: 'Colorado' },
  { value: 'CT', title: 'Connecticut' },
  { value: 'DE', title: 'Delaware' },
  { value: 'DC', title: 'District Of Columbia' },
  { value: 'FL', title: 'Florida' },
  { value: 'GA', title: 'Georgia' },
  { value: 'HI', title: 'Hawaii' },
  { value: 'ID', title: 'Idaho' },
  { value: 'IL', title: 'Illinois' },
  { value: 'IN', title: 'Indiana' },
  { value: 'IA', title: 'Iowa' },
  { value: 'KS', title: 'Kansas' },
  { value: 'KY', title: 'Kentucky' },
  { value: 'LA', title: 'Louisiana' },
  { value: 'ME', title: 'Maine' },
  { value: 'MD', title: 'Maryland' },
  { value: 'MA', title: 'Massachusetts' },
  { value: 'MI', title: 'Michigan' },
  { value: 'MN', title: 'Minnesota' },
  { value: 'MS', title: 'Mississippi' },
  { value: 'MO', title: 'Missouri' },
  { value: 'MT', title: 'Montana' },
  { value: 'NE', title: 'Nebraska' },
  { value: 'NV', title: 'Nevada' },
  { value: 'NH', title: 'New Hampshire' },
  { value: 'NJ', title: 'New Jersey' },
  { value: 'NM', title: 'New Mexico' },
  { value: 'NY', title: 'New York' },
  { value: 'NC', title: 'North Carolina' },
  { value: 'ND', title: 'North Dakota' },
  { value: 'OH', title: 'Ohio' },
  { value: 'OK', title: 'Oklahoma' },
  { value: 'OR', title: 'Oregon' },
  { value: 'PA', title: 'Pennsylvania' },
  { value: 'RI', title: 'Rhode Island' },
  { value: 'SC', title: 'South Carolina' },
  { value: 'SD', title: 'South Dakota' },
  { value: 'TN', title: 'Tennessee' },
  { value: 'TX', title: 'Texas' },
  { value: 'UT', title: 'Utah' },
  { value: 'VT', title: 'Vermont' },
  { value: 'VA', title: 'Virginia' },
  { value: 'VI', title: 'Virgin Islands' },
  { value: 'WA', title: 'Washington' },
  { value: 'WV', title: 'West Virginia' },
  { value: 'WI', title: 'Wisconsin' },
  { value: 'WY', title: 'Wyoming' },
];
export const NAME_SUFFIX_OPTIONS: FormOption<NameSuffix>[] = [
  { title: 'Jr.', value: NameSuffix.JR },
  { title: 'Sr.', value: NameSuffix.SR },
  { title: 'II', value: NameSuffix.II },
  { title: 'III', value: NameSuffix.III },
  { title: 'IV', value: NameSuffix.IV },
  { title: 'V', value: NameSuffix.V },
];
export const CITIZENSHIP_TYPE_OPTIONS: FormOption<CitizenshipType>[] = [
  { title: 'US Citizen', value: CitizenshipType.US_CITIZEN },
  { title: 'Permanent Resident-Alien', value: CitizenshipType.PERM_RESIDENT },
  { title: 'Non-Permanent Resident-Alien', value: CitizenshipType.NON_PERM_RESIDENT },
];
export const MILITARY_EXPERIENCE_OPTIONS: FormOption<MilitaryExperienceType>[] = [
  { title: 'None', value: MilitaryExperienceType.NONE },
  { title: 'Active Duty', value: MilitaryExperienceType.ACTIVE_DUTY },
  { title: 'Retired, discharged or separated', value: MilitaryExperienceType.RETIRED_DISCHARGED_SEPARATED },
  { title: 'Non-activated member of Reserve or National Guard', value: MilitaryExperienceType.RESERVE_OR_NATIONAL_GUARD },
  { title: 'Surviving spouse', value: MilitaryExperienceType.SURVIVING_SPOUSE },
];
export const EMPLOYMENT_OWNERSHIP_SHARE_OPTIONS: FormOption<EmploymentOwnershipShare>[] = [
  { title: 'An ownership share of less than 25%', value: EmploymentOwnershipShare.LESS_THAN_25_PERCENT },
  { title: 'An ownership share of 25% or more', value: EmploymentOwnershipShare.GREATER_THAN_OR_EQUAL_25_PERCENT },
];

export const VEHICLE_PURCHASE_PLAN_OPTIONS: FormOption<VehiclePurchasePlan>[] = [
  { title: 'Purchase from Dealer (New)', value: VehiclePurchasePlan.PURCHASE_FROM_DEALER_NEW },
  { title: 'Purchase from Dealer (Used)', value: VehiclePurchasePlan.PURCHASE_FROM_DEALER_USED },
  { title: 'Refinance Current Vehicle', value: VehiclePurchasePlan.REFINANCE_CURRENT_VEHICLE },
  { title: 'Lease Buyout', value: VehiclePurchasePlan.LEASE_BUYOUT },
  { title: 'Private Party Purchase', value: VehiclePurchasePlan.PRIVATE_PARTY_PURCHASE },
];

export const VEHICLE_TYPE_OPTIONS: FormOption<VehicleType>[] = [
  { title: 'Motorcycle',          value: VehicleType.MOTORCYCLE },
  { title: 'ATV',                 value: VehicleType.ATV },
  { title: 'Personal Watercraft', value: VehicleType.PERSONAL_WATERCRAFT },
  { title: 'Snowmobile',          value: VehicleType.SNOWMOBILE },
  { title: 'RV',                  value: VehicleType.RV },
  { title: 'Boat',                value: VehicleType.BOAT },
  { title: 'Other',               value: VehicleType.OTHER },
];

export const DESIRED_LOAN_TERM_OPTIONS: FormOption<DesiredLoanTerm>[] = [
  { title: '12', value: DesiredLoanTerm.MONTHS_12 },
  { title: '24', value: DesiredLoanTerm.MONTHS_24 },
  { title: '36', value: DesiredLoanTerm.MONTHS_36 },
  { title: '48', value: DesiredLoanTerm.MONTHS_48 },
  { title: '60', value: DesiredLoanTerm.MONTHS_60 },
  { title: '72', value: DesiredLoanTerm.MONTHS_72 },
];
export const CONSTRUCTION_LOAN_TYPE: FormOption<ConstructionLoanType>[] = [
  { title: 'One Closing', value: ConstructionLoanType.ONE },
  { title: 'Two Closing', value: ConstructionLoanType.TWO },
  { title: 'Not Sure', value: ConstructionLoanType.NOTSURE },
];

export const REVERSE_PROPERTY_TITLE_HELD: FormOption<ReverseMortgagePropertyTitleHeld>[] = [
  { title: 'Fee Simple', value: ReverseMortgagePropertyTitleHeld.FEE_SIMPLE },
  { title: 'Life Estate', value: ReverseMortgagePropertyTitleHeld.LIFE_ESTATE },
  { title: 'Leasehold', value: ReverseMortgagePropertyTitleHeld.LEASEHOLD },
  { title: 'Living Trust', value: ReverseMortgagePropertyTitleHeld.LIVING_TRUST },
  { title: 'Not Sure', value: ReverseMortgagePropertyTitleHeld.NOT_SURE },
];

export const COMMERCIAL_PROPERTY_TYPE_OPTIONS: FormOption<CommercialPurchasePropertyType>[] = [
  { title: 'Hotels/Hospitality', value: CommercialPurchasePropertyType.HOTELS },
  { title: 'Industrial',         value: CommercialPurchasePropertyType.INDUSTRIAL },
  { title: 'Retail',             value: CommercialPurchasePropertyType.RETAIL },
  { title: 'Office',             value: CommercialPurchasePropertyType.OFFICE },
  { title: 'Mixed Use',          value: CommercialPurchasePropertyType.MIXED_USE },
  { title: 'Other',              value: CommercialPurchasePropertyType.OTHER },
];

export const COMMERCIAL_ENTITY_TYPE_OPTIONS: FormOption<CommercialEntityType>[] = [
  { title: 'Sole Proprietorship',  value: CommercialEntityType.SOLE_PROPRIETORSHIP },
  { title: 'Partnership',          value: CommercialEntityType.PARTNERSHIP },
  { title: 'Corporation',          value: CommercialEntityType.CORPORATION },
  { title: 'S Corporation',        value: CommercialEntityType.S_CORP },
  { title: 'LLC',                  value: CommercialEntityType.LLC },
];

export const COMMERCIAL_BUSINESS_TYPE_OPTIONS: FormOption<CommercialBusinessType>[] = [
  { title: 'Construction',   value: CommercialBusinessType.CONSTRUCTION },
  { title: 'Manufacturing',  value: CommercialBusinessType.MANUFACTURING },
  { title: 'Retail',         value: CommercialBusinessType.RETAIL },
  { title: 'Service',        value: CommercialBusinessType.SERVICE },
  { title: 'Wholesale',      value: CommercialBusinessType.WHOLESALE },
  { title: 'Other',          value: CommercialBusinessType.OTHER },
];

export const COMMERCIAL_TREND_OPTIONS: FormOption<CommercialTrends>[] = [
  { title: 'Increasing',     value: CommercialTrends.INCREASING },
  { title: 'Decreasing',     value: CommercialTrends.DECREASING },
  { title: 'Stable',         value: CommercialTrends.STABLE },
];

export const COMMERCIAL_LOAN_HOLDER_OPTIONS: FormOption<CommercialLoanHolder>[] = [
  { title: 'Business',     value: CommercialLoanHolder.BUSINESS },
  { title: 'Individual',   value: CommercialLoanHolder.INDIVIDUAL },
];

export const COMMERCIAL_LIVING_SITUATION_OPTIONS: FormOption<LivingSituation>[] = [
  { title: 'Homeowner', value: LivingSituation.OWN },
  { title: 'Renter', value: LivingSituation.RENT },
];

export const COMMERCIAL_NUMBER_OF_STRUCTURES_OPTIONS: FormOption<NumberOfStructures>[] = [
  { title: '0',  value: NumberOfStructures.ZERO },
  { title: '1',  value: NumberOfStructures.ONE },
  { title: '2',  value: NumberOfStructures.TWO },
  { title: '3',  value: NumberOfStructures.THREE },
  { title: '4',  value: NumberOfStructures.FOUR },
  { title: '5+', value: NumberOfStructures.FIVE_PLUS },
];

export const REVERSE_MORTGAGE_LOAN_OPTIONS: FormOption<ReverseMortgageLoanType>[] = [
  { title: 'Line of Credit', value: ReverseMortgageLoanType.LINE_OF_CREDIT },
  { title: 'Monthly Payments', value: ReverseMortgageLoanType.MONTHLY_PAYMENTS },
];

export const REVERSE_MORTGAGE_LOAN_PURPOSE: FormOption<ReverseMortgageLoanPurpose>[] = [
  { title: 'Purchase a new home', value: ReverseMortgageLoanPurpose.PURCHASE_NEW_HOME },
  { title: 'Receive a cash benefit', value: ReverseMortgageLoanPurpose.REFINANCE_FOR_CASH },
  { title: 'Payoff your existing mortgage', value: ReverseMortgageLoanPurpose.REFINANCE_PAYOFF_EXISTING },
];

export const CREDIT_BOOSTER_ACCOUNTS: FormOption<CreditBoosterAccountTypes>[] = [
  { title: 'Savings Account', value: CreditBoosterAccountTypes.SAVINGS },
  { title: 'Certificate of Deposit (CD)', value: CreditBoosterAccountTypes.DEPOSIT },
];

export const YEAR_1970PLUS_TO_NOW: FormOption<number>[] = (() => {
  const currentYear = parseInt(moment().format('YYYY'));
  const returnValue = [];
  for (let x = currentYear; x > 1970; x--) {
    returnValue.push({ title: `${ x }`, value: `${ x }` });
  }
  returnValue.push({ title: `1970 or older`, value: '1970 or older' });
  return returnValue;
})();

export const EQUIPMENT_OR_INVENTORY_OPTIONS: FormOption<EquipmentOrInventory>[] = [
  { title: 'Equipment', value: EquipmentOrInventory.EQUIPMENT },
  { title: 'Inventory', value: EquipmentOrInventory.INVENTORY },
];
export const COMMERCIAL_PURCHASE_TIMING_OPTIONS: FormOption<CommercialLoanTiming>[] = [
  { title: 'Within 1 Month', value: CommercialLoanTiming.WITHIN_1_MONTH },
  { title: '1-3 Months', value: CommercialLoanTiming.ONE_THROUGH_THREE },
  { title: '3+ Months', value: CommercialLoanTiming.THREE_PLUS },
];

export const COMMERCIAL_NUMBER_OF_VEHICLES_OPTIONS: FormOption<NumberOfVehicles>[] = [
  { title: '1',  value: NumberOfVehicles.ONE },
  { title: '2',  value: NumberOfVehicles.TWO },
  { title: '3',  value: NumberOfVehicles.THREE },
  { title: '4',  value: NumberOfVehicles.FOUR },
  { title: '5+', value: NumberOfVehicles.FIVE_PLUS },
];

export const NEW_OR_USED_OPTIONS: FormOption<NewOrUsed>[] = [
  { title: 'New',  value: NewOrUsed.NEW },
  { title: 'Used', value: NewOrUsed.USED },
];

export const COMMERCIAL_VEHICLE_DESIRED_LOAN_TERM_OPTIONS: FormOption<DesiredLoanTerm>[] = [
  { title: '12', value: DesiredLoanTerm.MONTHS_12 },
  { title: '24', value: DesiredLoanTerm.MONTHS_24 },
  { title: '36', value: DesiredLoanTerm.MONTHS_36 },
  { title: '48', value: DesiredLoanTerm.MONTHS_48 },
  { title: '60', value: DesiredLoanTerm.MONTHS_60 },
];

export const BRIDGE_OR_CREDIT_OPTIONS: FormOption<BridgeOrCredit>[] = [
  { title: 'Bridge Loan',    value: BridgeOrCredit.BRIDGE },
  { title: 'Line of Credit', value: BridgeOrCredit.CREDIT },
];

export const HOME_STORIES_OPTIONS: FormOption<NumberOfStories>[] = [
  { title: '1', value: NumberOfStories.ONE },
  { title: '2', value: NumberOfStories.TWO },
  { title: '3', value: NumberOfStories.THREE },
  { title: '4', value: NumberOfStories.FOUR },
];

export const BUILDING_UNITS_OPTIONS: FormOption<NumberOfUnits>[] = [
  { title: '1', value: NumberOfUnits.ONE },
  { title: '2', value: NumberOfUnits.TWO },
  { title: '3', value: NumberOfUnits.THREE },
  { title: '4', value: NumberOfUnits.FOUR },
];

export const CONSTRUCTION_HOME_OPTIONS: FormOption<ConstructionHomeType>[] = [
  { title: 'Frame', value: ConstructionHomeType.FRAME },
  { title: 'Masonry', value: ConstructionHomeType.MASONRY },
  { title: 'Masonry Veneer', value: ConstructionHomeType.MASONRY_VENEER },
  { title: 'Fire Resistant', value: ConstructionHomeType.FIRE_RESISTANT },
  { title: 'Log', value: ConstructionHomeType.LOG },
];

export const CONSTRUCTION_ROOFING_OPTIONS: FormOption<ConstructionRoofingType>[] = [
  { title: 'Composition Shingle', value: ConstructionRoofingType.COMPOSITION_SHINGLE },
  { title: 'Architectural Shingle', value: ConstructionRoofingType.ARCHITECTURAL_SHINGLE },
  { title: 'Composition Over Wood', value: ConstructionRoofingType.COMPOSITION_OVER_WOOD },
  { title: 'Metal', value: ConstructionRoofingType.METAL },
  { title: 'Slate', value: ConstructionRoofingType.SLATE },
  { title: 'Plexiglass', value: ConstructionRoofingType.PLEXIGLASS },
  { title: 'Wood', value: ConstructionRoofingType.WOOD },
  { title: 'Tile', value: ConstructionRoofingType.TILE },
  { title: 'Tar and Gravel', value: ConstructionRoofingType.TAR_AND_GRAVEL },
  { title: 'Roll', value: ConstructionRoofingType.ROLL },
  { title: 'Rubber', value: ConstructionRoofingType.RUBBER },
  { title: 'Plastic', value: ConstructionRoofingType.PLASTIC },
  { title: 'Fiberglass', value: ConstructionRoofingType.FIBERGLASS },
  { title: 'Foam', value: ConstructionRoofingType.FOAM },
  { title: 'All Other', value: ConstructionRoofingType.ALL_OTHER },
];

export const RETIREMENT_ACCOUNT_TYPE_OPTIONS: FormOption<RetirementAccountType>[] = [
  { title: 'IRA', value  : RetirementAccountType.IRA },
  { title: '401K', value : RetirementAccountType.K_401 },
  { title: '403B', value : RetirementAccountType.B_403 },
  { title: '457', value  : RetirementAccountType.FOUR57 },
];

export const PERSONAL_PROPERTY_TYPE_OPTIONS: FormOption<PersonalPropertyType>[] = [
  { title: 'Jewelry', value: PersonalPropertyType.JEWELRY },
  { title: 'Vehicle', value: PersonalPropertyType.VEHICLE },
  { title: 'Other', value: PersonalPropertyType.OTHER },
];
export const TAX_PAYABLE_TYPE_OPTIONS: FormOption<TaxType>[] = [
  { title: 'Property', value: TaxType.PROPERTY },
  { title: 'Other', value: TaxType.OTHER },
];

export const COMMERCIAL_BUSINESS_OWNERSHIP_TYPE_OPTIONS: FormOption<CommercialBusinessOwnershipType>[] = [
  { title: 'N/A',      value: CommercialBusinessOwnershipType.NA       },
  { title: 'Minority', value: CommercialBusinessOwnershipType.MINORITY },
  { title: 'Women',    value: CommercialBusinessOwnershipType.WOMEN    },
  { title: 'LGBTQI+',  value: CommercialBusinessOwnershipType.LGBTQI   },
];

export const COMMERCIAL_LOAN_TYPE_OPTIONS: FormOption<FormName>[] = [
  { title: 'Refinance a Commercial Property', value: FormName.COMMERCIALREFINANCE  },
  { title: 'Purchase a Commercial Property',  value: FormName.COMMERCIALPURCHASE   },
  { title: 'Purchase Equipment or Inventory', value: FormName.COMMERCIALEQUIPMENT  },
  { title: 'Bridge Loan or Line of Credit',   value: FormName.COMMERCIALBRIDGELOAN },
  { title: 'Purchase Commercial Vehicles',    value: FormName.COMMERCIALVEHICLE    },
];

export const COMMERCIAL_COLLATERAL_DESCRIPTION_OPTIONS: FormOption<CommercialCollateralDescriptionType>[] = [
  { title: 'All Business Assets',   value: CommercialCollateralDescriptionType.ABA                  },
  { title: 'Commercial Equipment',  value: CommercialCollateralDescriptionType.COMMERCIAL_EQUIPMENT },
  { title: 'Commercial Vehicle',    value: CommercialCollateralDescriptionType.COMMERCIAL_VEHICLE   },
];

export const COMMERCIAL_ENTITY_TYPE_2_OPTIONS: FormOption<CommercialEntityType>[] = [
  { title: 'Association',          value: CommercialEntityType.ASSOCIATION       },
  { title: 'Proprietorship',       value: CommercialEntityType.PROPRIETORSHIP    },
  { title: 'Trust',                value: CommercialEntityType.TRUST             },
  { title: 'Partnership',          value: CommercialEntityType.PARTNERSHIP       },
  { title: 'Government Entity',    value: CommercialEntityType.GOVERNMENT_ENTITY },
  { title: 'Corporation',          value: CommercialEntityType.CORPORATION       },
  { title: 'LLC',                  value: CommercialEntityType.LLC               },
  { title: 'Non-Profit',           value: CommercialEntityType.NON_PROFIT        },
  { title: 'Individual',           value: CommercialEntityType.INDIVIDUAL        },
];

export const SB_DESIRED_LOAN_TERM_OPTIONS: FormOption<DesiredLoanTerm>[] = [
  { title: '36 Months', value: DesiredLoanTerm.MONTHS_36 },
  { title: '48 Months', value: DesiredLoanTerm.MONTHS_48 },
  { title: '60 Months', value: DesiredLoanTerm.MONTHS_60 },
];
export const SMALL_BUSINESS_PURCHASE_TIMING_OPTIONS: FormOption<CommercialLoanTiming>[] = [
  { title: 'Within 1 Week',  value: CommercialLoanTiming.WITHIN_1_WEEK },
  { title: 'Within 1 Month', value: CommercialLoanTiming.WITHIN_1_MONTH },
  { title: '1-3 Months',     value: CommercialLoanTiming.ONE_THROUGH_THREE },
];

export const LoanTypeOptions: FormOption<ProductFamily>[] = [
  { title: 'Conventional', value: ProductFamily.CONVENTIONAL },
  { title: 'USDA',         value: ProductFamily.USDA         },
  { title: 'VA',           value: ProductFamily.VA           },
];

export const YES_OR_NO_AUTO_INSURANCE_OPTIONS: FormOption<YesOrNo>[] = [
  { title: 'Yes, I would like to receive a quote', value: YesOrNo.YES },
  { title: 'No, not at this time', value: YesOrNo.NO },
];

export const CURRENT_LOAN_TYPE_OPTIONS: FormOption<CurrentLoanType>[] = [
  { title: '30 Year Fixed', value: CurrentLoanType.THIRTY },
  { title: '20 Year Fixed', value: CurrentLoanType.TWENTY },
  { title: '15 Year Fixed', value: CurrentLoanType.FIFTEEN },
  { title: '10 Year Fixed', value: CurrentLoanType.TEN },
  { title: 'Balloon Loan',  value: CurrentLoanType.BALLOON },
  { title: 'ARM',           value: CurrentLoanType.ARM },
  { title: 'Other',         value: CurrentLoanType.OTHER },
];

export const REFINANCE_TURBO_REO_LIABILITY_USAGE: FormOption<LiabilityUsage>[] = [
  { title: 'This is the home I am refinancing', value: LiabilityUsage.COLLATERAL_HOME },
  { title: 'This is another home I own', value: LiabilityUsage.OTHER_HOME },
  { title: 'This is not my property', value: LiabilityUsage.NOT_MY_HOME },
  { title: 'This is my primary home', value: LiabilityUsage.PRIMARY_HOME },
];

