import { Section } from 'app/models/types';
import { FieldNames } from 'app/models/fields/names';
import { SmallBusinessCollectSsnStep } from '../../components/SmallBusinessCollectSsnStep';
import {
  ConfirmationCommercialSmallBusiness,
} from 'app/components/ConfirmationCommercialSmallBusinessComponent';
import {
  SB_LINE_OF_CREDIT_LOAN_INFO,
  SB_LINE_OF_CREDIT_BUSINESS_INFO,
  SB_LINE_OF_CREDIT_OWNER_INFO,
  SB_LINE_OF_CREDIT_FINANCIAL_INFO,
  SB_LINE_OF_CREDIT_SSN,
  SB_LINE_OF_CREDIT_FINAL,
} from 'app/models/sections/constants';
import {
  SB_LINE_OF_CREDIT_QUESTIONS,
} from '../questions/sb-line-of-credit';
import { FormTerms } from '../../components/FormAgreements/FormTerms';
import * as React from 'react';
import { Questions } from '../questions/Questions';
import { isExcludingSsnQuestions, isNonProfit } from '../fields/conditionals';
import { handleSBFormSubmit } from '../../actions/form/small-business/actions';

export const getSBLineOfCreditSectionById = (id: number): Section => {
  return SB_LINE_OF_CREDIT_SECTIONS.find((section) => section.id === id) as Section;
};

const sbLineOfCreditQuestions = new Questions(SB_LINE_OF_CREDIT_QUESTIONS);
const SB_LINE_OF_CREDIT_SECTIONS: Section[] = [
  {
    id        : SB_LINE_OF_CREDIT_LOAN_INFO,
    questions : sbLineOfCreditQuestions.getByName([
      FieldNames.loanAmount,
      FieldNames.commercialLoanPurpose,
      FieldNames.commercialCollateralList,
      FieldNames.commercialLoanTiming,
    ]),
    title : 'Tell us more about your loan',
    agreement: <FormTerms />,
  },
  {
    id        : SB_LINE_OF_CREDIT_BUSINESS_INFO,
    title     : 'Tell us about your business',
    questions : sbLineOfCreditQuestions.getByName([
      FieldNames.commercialBusinessName,
      FieldNames.commercialDBA,
      FieldNames.commercialTIN,
      FieldNames.commercialStreet,
      FieldNames.commercialStreet2,
      FieldNames.commercialCity,
      FieldNames.commercialState,
      FieldNames.commercialZip,
      FieldNames.commercialPhone,
      FieldNames.commercialSameMailingAddress,
      FieldNames.commercialMailingStreet,
      FieldNames.commercialMailingStreet2,
      FieldNames.commercialMailingCity,
      FieldNames.commercialMailingState,
      FieldNames.commercialMailingZip,
      FieldNames.commercialWebsite,
      FieldNames.commercialStateOfOrganization,
      FieldNames.commercialEntityType,
    ]),
  },
  {
    id             : SB_LINE_OF_CREDIT_OWNER_INFO,
    questions      : sbLineOfCreditQuestions.getByName([
      FieldNames.commercialOwnerList,
    ]),
    showIf: [(values) => !isNonProfit(values)],
    title          : 'Tell us about all of the Business Owners that have at least 20%',
  },
  {
    id          : SB_LINE_OF_CREDIT_FINANCIAL_INFO,
    questions   : sbLineOfCreditQuestions.getByName([
      FieldNames.commercialBusinessCashAndEquivalents,
      FieldNames.commercialBusinessTotalAssets,
      FieldNames.commercialBusinessTotalLiabilities,
      FieldNames.commercialBusinessNetWorth,
      FieldNames.commercialBusinessTotalSales,
      FieldNames.commercialBusinessAnnualInterestExpense,
      FieldNames.commercialBusinessEBITA,
      FieldNames.commercialBusinessCheckingAccountBalance,
    ]),
    title        : 'Tell us about the financials of the business',
    submitIf     : [(values) => isNonProfit(values) || isExcludingSsnQuestions(values)],
    submitAction : handleSBFormSubmit,
  },
  {
    id            : SB_LINE_OF_CREDIT_SSN,
    ifNoQuestions : SmallBusinessCollectSsnStep,
    showIf        : [(values) => !isExcludingSsnQuestions(values) && !isNonProfit(values)],
    submitAction  : handleSBFormSubmit,
  },
  {
    id              : SB_LINE_OF_CREDIT_FINAL,
    ifNoQuestions   : ConfirmationCommercialSmallBusiness,
    hideProgressBar : true,
  },
];
