import { useTranslation } from 'react-i18next';
import { FormControl } from '@material-ui/core';
import { FieldArray } from 'redux-form';
import { requiredArray } from '../../../util/validators';
import React from 'react';
import { DynamicComponentInput, DynamicFormFieldArray, renderDynamicComponent } from './DynamicComponent';
import { FieldNames } from '../../../models/fields/names';
import { DynamicFormFieldArrayType } from '../../../models/options/enums';
import { taxesInitialValue } from '../../../util/initial-values';
import { TAX_PAYABLE_TYPE_OPTIONS } from '../../../models/options/options';

const taxInputs: DynamicComponentInput[] = [
  {
    fieldName  : FieldNames.financialStatementTaxType,
    isOptional : false,
    label      : 'Type of Tax',
    options    : TAX_PAYABLE_TYPE_OPTIONS,
    type       : DynamicFormFieldArrayType.SELECT,
  },
  {
    fieldName  : FieldNames.financialStatementAmountOwed,
    isOptional : false,
    label      : 'Amount Owed',
    type       : DynamicFormFieldArrayType.CURRENCY,
  },
  {
    fieldName  : FieldNames.financialStatementDescription,
    isOptional : true,
    label      : 'Description',
    type       : DynamicFormFieldArrayType.TEXT_INPUT,
  },
];

const taxProps: DynamicFormFieldArray = {
  addButtonText : 'Account',
  initialValue  : taxesInitialValue,
  inputs        : taxInputs,
};

export const TaxesFFAComponent = ({ ...props }) => {
  const { name, options } = props;
  const { t }    = useTranslation();

  return (
    <FormControl fullWidth>
      <FieldArray
        component = {renderDynamicComponent}
        name      = { name }
        props     = { { dynamicProps: taxProps, name, t, options } }
        validate  = {[
          requiredArray([]),
        ]}
      />
    </FormControl>
  );
};
