import React from 'react';
import { autoPrequalQuestionsByName } from 'app/models/questions/auto-prequal';
import { FormTerms } from 'app/components/FormAgreements/FormTerms';
import { AuthorizeCreditReport } from 'app/components/FormAgreements/AuthorizeCreditReport';
import * as FormActions from 'app/actions/form/actions';
import { ajaxValidateBorrowerResidence, ajaxValidateCoBorrowerResidence } from 'app/actions/form/address-validation/actions';
import { Section } from 'app/models/types';
import { FormParagraphs } from 'app/models/paragraphs';
import { PrequalSummary } from 'app/components/PrequalSummary/PrequalSummary';
import { FieldNames } from 'app/models/fields/names';
import {
  LoanOfficerCondition,
  hasCoBorrower,
  hasBankrupt,
  hasForeclosed,
  noCitizenship,
  hasRealEstateLiabilities,
  isCoBorrowerNotEmployed,
  isCoBorrowerEmployee,
  isCoBorrowerEmploymentOther,
  isCoBorrowerRetired,
  isCoBorrowerSelfEmployed,
  coBorrowerHasDifferentAddress,
  isCoBorrowerIncomeVerified,
  isBorrowerIncomeVerified,
} from 'app/models/fields/conditionals';
import { getAutoPrequalDeclarationQuestions } from 'app/models/questions/declarations';
import { PrequalSummaryHeader } from 'app/components/PrequalSummary/PrequalSummaryHeader';
import { ajaxPostCreditReportForAutoPrequal } from 'app/actions/form/credit-report/actions';
import {
  AUTO_PREQUAL_ANNUAL_INFO,
  AUTO_PREQUAL_BUYING_INFO,
  AUTO_PREQUAL_COBORROWER,
  AUTO_PREQUAL_COBORROWER_EMPLOYMENT_INFO,
  AUTO_PREQUAL_COBORROWER_EMPLOYMENT_TYPE,
  AUTO_PREQUAL_CREDIT_AUTHORIZATION,
  AUTO_PREQUAL_EMPLOYMENT_INFO,
  AUTO_PREQUAL_EMPLOYMENT_TYPE,
  AUTO_PREQUAL_FINANCIAL_HISTORY,
  AUTO_PREQUAL_LIVING_PROPERTY,
  AUTO_PREQUAL_PERSONAL_INFO,
  AUTO_PREQUAL_PREQUAL_AMOUNT,
  AUTO_PREQUAL_PURCHASE_INFO,
  AUTO_PREQUAL_REAL_ESTATE_LIABILITIES,
  AUTO_PREQUAL_SUMMARY,
  AUTO_PREQUAL_VETERAN,
} from "./constants";

export const getAutoPrequalSectionById = (id: number): Section => {
  return AUTO_PREQUAL_SECTIONS.find((section) => section.id === id) as Section;
};

const AUTO_PREQUAL_SECTIONS: Section[] = [
  {
    id       : AUTO_PREQUAL_PERSONAL_INFO,
    title    : 'Enter your personal information',
    questions: autoPrequalQuestionsByName([
      FieldNames.firstName,
      FieldNames.middleName,
      FieldNames.lastName,
      FieldNames.suffixName,
      FieldNames.email,
      FieldNames.phone,
      FieldNames.dateOfBirth,
    ]),
    agreement     : <FormTerms />,
    hideBackButton: true,
  },
  {
    id       : AUTO_PREQUAL_BUYING_INFO,
    questions: autoPrequalQuestionsByName([
      FieldNames.buyingProcess,
      FieldNames.purchaseTimeline,
    ]),
  },
  {
    id       : AUTO_PREQUAL_PURCHASE_INFO,
    title    : 'Tell us about your new purchase',
    questions: autoPrequalQuestionsByName([
      FieldNames.homeValue,
      FieldNames.propertyType,
      FieldNames.propertyUsage,
      FieldNames.propertyZip,
    ]),
  },
  {
    id       : AUTO_PREQUAL_ANNUAL_INFO,
    title    : 'Estimated taxes, insurance, and fees',
    paragraph: FormParagraphs.estimatedAmounts,
    questions: autoPrequalQuestionsByName([
      FieldNames.yearTaxes,
      FieldNames.yearInsure,
      FieldNames.yearAssociate,
    ]),
  },
  {
    id       : AUTO_PREQUAL_LIVING_PROPERTY,
    questions: autoPrequalQuestionsByName([
      FieldNames.livingPropertyStreet,
      FieldNames.livingPropertyStreet2,
      FieldNames.livingPropertyCity,
      FieldNames.livingPropertyState,
      FieldNames.livingPropertyZip,
      FieldNames.maritalStatus,
      FieldNames.coBorrowerYN,
    ]),
    submitAction: ajaxValidateBorrowerResidence,
  },
  {
    id       : AUTO_PREQUAL_COBORROWER,
    title    : 'Enter your co-borrower\'s personal information',
    questions: autoPrequalQuestionsByName([
      FieldNames.coBorrowerFirstName,
      FieldNames.coBorrowerMiddleName,
      FieldNames.coBorrowerLastName,
      FieldNames.coBorrowerSuffixName,
      FieldNames.coBorrowerDOB,
      FieldNames.coBorrowerSameAddress,
      FieldNames.coBorrowerLivingPropertyStreet,
      FieldNames.coBorrowerLivingPropertyStreet2,
      FieldNames.coBorrowerLivingPropertyCity,
      FieldNames.coBorrowerLivingPropertyState,
      FieldNames.coBorrowerLivingPropertyZip,
    ]),
    showIf: [hasCoBorrower],
    submitAction: (formValues) =>
      coBorrowerHasDifferentAddress(formValues) ? ajaxValidateCoBorrowerResidence(formValues) : ajaxValidateBorrowerResidence(formValues),
  },
  {
    id       : AUTO_PREQUAL_VETERAN,
    questions: autoPrequalQuestionsByName([
      FieldNames.veteranYN,
      FieldNames.veteranLoanYN,
    ]),
    conditionalIf : {
      referToLoIf : [LoanOfficerCondition.isVeteranLoan],
    },
  },
  {
    id       : AUTO_PREQUAL_CREDIT_AUTHORIZATION,
    questions: autoPrequalQuestionsByName([
      FieldNames.loanOfficer,
    ]),
    agreement: <AuthorizeCreditReport />,
  },
  {
    id          : AUTO_PREQUAL_FINANCIAL_HISTORY,
    title       : 'A few more questions about your financial history',
    questions: getAutoPrequalDeclarationQuestions(),
    conditionalIf : {
      referToLoIf : [
        LoanOfficerCondition.hasBankrupt,
        LoanOfficerCondition.hasForeclosed,
        LoanOfficerCondition.isBorrowerNotCitizen,
      ],
    },
    submitAction: ajaxPostCreditReportForAutoPrequal,
    /* Only pull credit report if borrower is citizen, and has no bankrupt or foreclosure */
    submitIf    : [(formValues) =>
      !hasBankrupt(FieldNames.decBorrower)(formValues) &&
      !hasForeclosed(FieldNames.decBorrower)(formValues) &&
      !noCitizenship(FieldNames.decBorrower)(formValues),
    ],
    showLoanOfficer: true,
  },
  {
    id             : AUTO_PREQUAL_REAL_ESTATE_LIABILITIES,
    title          : 'Real Estate Liabilities',
    paragraph      : FormParagraphs.realEstateLiabilities,
    questions      : autoPrequalQuestionsByName([FieldNames.realEstateLiabilities]),
    showLoanOfficer: true,
    showIf         : [hasRealEstateLiabilities],
  },
  {
    id       : AUTO_PREQUAL_EMPLOYMENT_TYPE,
    questions: autoPrequalQuestionsByName([
      FieldNames.employmentType,
    ]),
    showLoanOfficer: true,
  },
  {
    id       : AUTO_PREQUAL_EMPLOYMENT_INFO,
    title    : 'Tell us about your income',
    questions: autoPrequalQuestionsByName([
      FieldNames.employBase,
      FieldNames.employOvertime,
      FieldNames.employCommission,
      FieldNames.employBonus,
      FieldNames.additionalIncome,
      FieldNames.retirementIncome,
      FieldNames.otherIncome,
      FieldNames.incomeVerifiable,
    ]),
    showLoanOfficer: true,
    submitAction   : FormActions.handleAutoPrequalGetRates,
    submitIf       : [(values) => !hasCoBorrower(values) && isBorrowerIncomeVerified(values)],
    submitText     : 'Submit',
    conditionalIf : {
      referToLoIf : [LoanOfficerCondition.isBorrowerIncomeUnverified],
    },
  },
  {
    id       : AUTO_PREQUAL_COBORROWER_EMPLOYMENT_TYPE,
    questions: autoPrequalQuestionsByName([
      FieldNames.coBorrowerEmploymentType,
    ]),
    showLoanOfficer: true,
    showIf         : [hasCoBorrower],
    submitAction   : FormActions.handleAutoPrequalGetRates,
    submitIf       : [isCoBorrowerNotEmployed],
    submitText     : 'Submit',
  },
  {
    id       : AUTO_PREQUAL_COBORROWER_EMPLOYMENT_INFO,
    title    : 'Tell us about your co-borrower\'s income',
    questions: autoPrequalQuestionsByName([
      FieldNames.coBorrowerEmployBase,
      FieldNames.coBorrowerEmployOvertime,
      FieldNames.coBorrowerEmployCommission,
      FieldNames.coBorrowerEmployBonus,
      FieldNames.coBorrowerAdditionalIncome,
      FieldNames.coBorrowerRetirementIncome,
      FieldNames.coBorrowerOtherIncome,
      FieldNames.coBorrowerIncomeVerifiable,
    ]),
    showLoanOfficer: true,
    submitAction   : FormActions.handleAutoPrequalGetRates,
    showIf         : [
      isCoBorrowerEmployee,
      isCoBorrowerEmploymentOther,
      isCoBorrowerRetired,
      isCoBorrowerSelfEmployed,
    ],
    submitText     : 'Submit',
    submitIf       : [(values) => isCoBorrowerIncomeVerified(values)],
    conditionalIf : {
      referToLoIf : [LoanOfficerCondition.isCoBorrowerIncomeUnverified],
    },
  },
  {
    id       : AUTO_PREQUAL_PREQUAL_AMOUNT,
    questions: autoPrequalQuestionsByName([
      FieldNames.prequalAmount,
    ]),
    centerSubmitButton: true,
    fixedSubmitButton : true,
    hideBackButton    : true,
    hidePartialButton : true,
    removePadding     : true,
    submitAction      : FormActions.handleAutoPrequalSubmit,
    submitText        : 'Get Your Pre-Qualification Letter',
  },
  {
    id               : AUTO_PREQUAL_SUMMARY,
    hideBackButton   : true,
    hideForwardButton: true,
    hideProgressBar  : true,
    ifNoQuestions    : PrequalSummary,
    removePadding    : true,
    showLoanOfficer  : true,
    useTopSection    : <PrequalSummaryHeader />,
  },
];
