import { FieldNames } from '../../models/fields/names';
import {
  hasFSCreditCard,
  hasFSLoan,
  hasFSMortgage,
  hasFSNote,
  hasFSPersonalProperty,
  hasFSRealEstate,
  hasFSRetirementAccount,
  hasFSStock,
  hasFSTax,
} from '../../models/fields/conditionals';
import {
  FinancialStatement,
  FinancialStatementCreditCard,
  FinancialStatementLoansPayable,
  FinancialStatementMortgages,
  FinancialStatementNotesAndContracts,
  FinancialStatementPersonalProperty,
  FinancialStatementRealEstate,
  FinancialStatementRetirementAccount,
  FinancialStatementStock,
  FinancialStatementTaxesPayable,
} from '@lenderful/domain';

export const mapFinancialStatement = (formData) => {
  const { creditCards, totalCreditCardValue }             = mapCreditCards(formData);
  const { loans, totalLoansValue }         = mapLoans(formData);
  const { mortgages, totalMortgagesValue } = mapMortgages(formData);
  const { notesAndContracts, totalNotesValue }         = mapNotesAndContracts(formData);
  const { personalProperties, totalPersonalValue }             = mapPersonalProperty(formData);
  const { realEstate, totalRealEstateValue }             = mapRealEstate(formData);
  const { retirementAccounts, totalRetirementValue }             = mapRetirementAccounts(formData);
  const { stocks, totalStockValue }       = mapStocks(formData);
  const { taxes, totalTaxValue }           = mapTaxes(formData);
  let financialStatementRequest: FinancialStatement = {
    creditCards,
    dateOfBirth: formData[FieldNames.dateOfBirth].substring(0,10),
    email: formData[FieldNames.email],
    firstName: formData[FieldNames.firstName],
    fullAddress : `${ (formData[FieldNames.livingPropertyStreet2] ?
      formData[FieldNames.livingPropertyStreet] + ' ' + formData[FieldNames.livingPropertyStreet2] :
      formData[FieldNames.livingPropertyStreet] )} ${ formData[FieldNames.livingPropertyCity] }, ${ formData[FieldNames.livingPropertyState] } ${ formData[FieldNames.livingPropertyZip] }`,
    middleName: formData[FieldNames.middleName],
    lastName : formData[FieldNames.lastName],
    loans,
    mortgages,
    netWorth: 0,
    notesAndContracts,
    personalProperties,
    phone: formData[FieldNames.phone],
    realEstate,
    retirementAccounts,
    stocks,
    suffixName : formData[FieldNames.suffixName],
    taxes,
    totalAssetsValue: totalNotesValue + totalPersonalValue + totalRealEstateValue + totalRetirementValue + totalStockValue,
    totalCreditCardValue,
    totalLiabilitiesValue: totalCreditCardValue + totalLoansValue + totalMortgagesValue + totalTaxValue,
    totalLoansValue,
    totalMortgagesValue,
    totalNotesValue,
    totalPersonalValue,
    totalRealEstateValue,
    totalRetirementValue,
    totalStockValue,
    totalTaxValue,
  };

  financialStatementRequest.netWorth = financialStatementRequest.totalAssetsValue - financialStatementRequest.totalLiabilitiesValue;

  return financialStatementRequest;
};

const mapCreditCards = (formData: any): { creditCards: FinancialStatementCreditCard[], totalCreditCardValue: number } => {
  const creditCards = [];
  let totalCreditCardValue = 0;
  if(hasFSCreditCard(formData)) {
    formData[FieldNames.financialStatementCreditCards].forEach(element => {
      totalCreditCardValue = totalCreditCardValue + element[FieldNames.financialStatementCurrentBalance];
      creditCards.push({
        creditor       : element[FieldNames.financialStatementCreditor],
        currentBalance : element[FieldNames.financialStatementCurrentBalance],
        monthlyPayment : element[FieldNames.financialStatementMonthlyPayment],
      });
    });
  }
  return { creditCards, totalCreditCardValue };
};

const mapLoans = (formData: any): { loans: FinancialStatementLoansPayable[], totalLoansValue: number } => {
  const loans = [];
  let totalLoansValue = 0;
  if(hasFSLoan(formData)) {
    formData[FieldNames.financialStatementLoans].forEach(element => {
      totalLoansValue = totalLoansValue + element[FieldNames.financialStatementCurrentBalance];
      loans.push({
        creditor       : element[FieldNames.financialStatementCreditor],
        currentBalance : element[FieldNames.financialStatementCurrentBalance],
        description    : element[FieldNames.financialStatementDescription],
        maturityDate   : element[FieldNames.financialStatementMaturityDate].substring(0,10),
        monthlyPayment : element[FieldNames.financialStatementMonthlyPayment],
      });
    });
  }
  return { loans, totalLoansValue };
};

const mapMortgages = (formData: any): { mortgages: FinancialStatementMortgages[], totalMortgagesValue: number } => {
  const mortgages = [];
  let totalMortgagesValue = 0;
  if(hasFSMortgage(formData)) {
    formData[FieldNames.financialStatementMortgages].forEach(element => {
      totalMortgagesValue = totalMortgagesValue + element[FieldNames.financialStatementCurrentBalance];
      mortgages.push({
        creditor       : element[FieldNames.financialStatementCreditor],
        currentBalance : element[FieldNames.financialStatementCurrentBalance],
        location       : element[FieldNames.financialStatementLocation],
        maturityDate   : element[FieldNames.financialStatementMaturityDate].substring(0,10),
        monthlyPayment : element[FieldNames.financialStatementMonthlyPayment],
      });
    });
  }
  return { mortgages, totalMortgagesValue };
};

const mapNotesAndContracts = (formData: any): { notesAndContracts: FinancialStatementNotesAndContracts[], totalNotesValue: number } => {
  const notesAndContracts = [];
  let totalNotesValue = 0;
  if(hasFSNote(formData)) {
    formData[FieldNames.financialStatementNotes].forEach(element => {
      totalNotesValue = totalNotesValue + element[FieldNames.financialStatementBalanceOwed];
      notesAndContracts.push({
        balanceOwed    : element[FieldNames.financialStatementBalanceOwed],
        maturityDate   : element[FieldNames.financialStatementMaturityDate].substring(0,10),
        monthlyPayment : element[FieldNames.financialStatementMonthlyPayment],
        purpose        : element[FieldNames.financialStatementNotePurpose],
        owingParty     : element[FieldNames.financialStatementOwingParty],
      });
    });
  }
  return { notesAndContracts, totalNotesValue };
};

const mapPersonalProperty = (formData: any): { personalProperties: FinancialStatementPersonalProperty[], totalPersonalValue: number } => {
  const personalProperties = [];
  let totalPersonalValue = 0;
  if(hasFSPersonalProperty(formData)) {
    formData[FieldNames.financialStatementPersonalProperties].forEach(element => {
      totalPersonalValue = totalPersonalValue + element[FieldNames.financialStatementMarketValue];
      personalProperties.push({
        description : element[FieldNames.financialStatementDescription],
        marketValue  : element[FieldNames.financialStatementMarketValue],
        propertyType : element[FieldNames.financialStatementPersonalType],
      });
    });
  }
  return { personalProperties, totalPersonalValue };
};

const mapRealEstate = (formData: any): { realEstate: FinancialStatementRealEstate[], totalRealEstateValue: number } => {
  const realEstate = [];
  let totalRealEstateValue = 0;
  if(hasFSRealEstate(formData)) {
    formData[FieldNames.financialStatementRealEstate].forEach(element => {
      totalRealEstateValue = totalRealEstateValue + element[FieldNames.financialStatementMarketValue];
      realEstate.push({
        description     : element[FieldNames.financialStatementDescription],
        location        : element[FieldNames.financialStatementLocation],
        marketValue     : element[FieldNames.financialStatementMarketValue],
        mortgageBalance : element[FieldNames.financialStatementMortgageBalance],
        purchaseDate    : element[FieldNames.financialStatementPurchaseDate].substring(0,10),
      });
    });
  }
  return { realEstate, totalRealEstateValue };
};

const mapRetirementAccounts = (formData: any): { retirementAccounts: FinancialStatementRetirementAccount[], totalRetirementValue: number } => {
  const retirementAccounts = [];
  let totalRetirementValue = 0;
  if(hasFSRetirementAccount(formData)) {
    formData[FieldNames.financialStatementRetirementAccounts].forEach(element => {
      totalRetirementValue = totalRetirementValue + element[FieldNames.financialStatementMarketValue];
      retirementAccounts.push({
        accountType          : element[FieldNames.financialStatementRetirementType],
        financialInstitution : element[FieldNames.financialStatementFinancialInstitution],
        marketValue          : element[FieldNames.financialStatementMarketValue],
      });
    });
  }
  return { retirementAccounts, totalRetirementValue };
};

const mapStocks = (formData: any): { stocks: FinancialStatementStock[], totalStockValue: number } => {
  const stocks = [];
  let totalStockValue = 0;
  if(hasFSStock(formData)) {
    formData[FieldNames.financialStatementStocks].forEach(element => {
      totalStockValue = totalStockValue + element[FieldNames.financialStatementMarketValue];
      stocks.push({
        marketValue    : element[FieldNames.financialStatementMarketValue],
        numberOfShares : element[FieldNames.financialStatementNumOfShares],
        securityName   : element[FieldNames.financialStatementSecurityName],
      });
    });
  }
  return { stocks, totalStockValue };
};

const mapTaxes = (formData: any): { taxes: FinancialStatementTaxesPayable[], totalTaxValue: number } => {
  const taxes = [];
  let totalTaxValue = 0;
  if(hasFSTax(formData)) {
    formData[FieldNames.financialStatementTaxes].forEach(element => {
      totalTaxValue = totalTaxValue + element[FieldNames.financialStatementAmountOwed];
      taxes.push({
        amount      : element[FieldNames.financialStatementAmountOwed],
        description : element[FieldNames.financialStatementDescription],
        taxType     : element[FieldNames.financialStatementTaxType],
      });
    });
  }
  return { taxes, totalTaxValue };
};
