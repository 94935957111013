import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import { ImageSection } from 'app/components/ImageSection';
import { RootState } from 'app/store/types';
import { getFormName } from '../../routes/helpers';
import { DocumentUploadButton } from 'app/components/DocumentUploadButton';
import { Button } from '../Button';
import download from 'downloadjs';
import { getFormValues } from 'redux-form';
import { FormName } from '../../models/options/enums';
import { FieldNames } from '../../models/fields/names';
import moment from 'moment';

type MappedProps = ReturnType<typeof mapStateToProps>;

const FinancialStatementConfirmationComponent = (props: MappedProps) => {
  const {
    confirmationImage,
  } = props;
  const { t } = useTranslation();

  const handleClick = () => {
    return (download(
      props.values['summary'],
      `${ props.values[FieldNames.lastName] }-${ props.values[FieldNames.firstName] }-${moment().millisecond()}-Financial-Statement-Summary` ,
      'application/pdf',
    ),
    download(
      props.values['details'],
      `${ props.values[FieldNames.lastName] }-${ props.values[FieldNames.firstName] }-${moment().millisecond()}-Financial-Statement-Details` ,
      'application/pdf',
    ));
  };

  return (
    <Grid container={false}>
      <Grid item classes={{ item: 'noStepper' }}>
        <ImageSection image={confirmationImage}>
          <Typography variant='h6' classes={{ h6: 'titleMargin section' }}>
            {t('confirmation.title', { defaultValue: 'Thank you!' })}
          </Typography>
          <Typography variant='body2' paragraph={true}>
            {t('confirmation.paragraph1', {
              defaultValue:
                'Please download your Personal Financial Statement, sign it, and upload the final copy.',
            })}
          </Typography>
          <Grid container justifyContent='center'>
            <Grid item>
              <Button
                classes  = {{ root: 'longButton' }}
                color    = "primary"
                variant  = "contained"
                onClick  = {handleClick}
              >
                {t('financialStatement.button.download', { defaultValue: 'Download Statement' })}
              </Button>
              <DocumentUploadButton />
            </Grid>
          </Grid>
        </ImageSection>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: RootState, ownProps: any) => ({
  confirmationImage : state.config.confirmationImage,
  formName : getFormName(state.router.location.pathname),
  values: getFormValues(FormName.FINANCIALSTATEMENT)(state),
  ...ownProps,
});

export const FinancialStatementConfirmation = connect(mapStateToProps)(FinancialStatementConfirmationComponent);
