import moment from 'moment';
import {
  Appointment,
  Asset,
  Binary,
  Borrower,
  BorrowerFieldName,
  Construction,
  DeclarationExplanation,
  Employment,
  Income,
  Liability,
  LoanApplication,
  ReoProperty,
  Residence,
  ResidenceDetailType,
} from 'app/api/loan-application/types';
import { FieldNames as Fields, FieldNames } from 'app/models/fields/names';
import {
  AccountType,
  CitizenshipType,
  DeclarationType,
  FormName,
  LivingSituation,
  YesOrNo,
} from 'app/models/options/enums';
import { getLoanAmount } from 'app/api/helpers';
import { LoanPurpose } from 'app/api/rates/types';
import { declarationInitialValue } from 'app/util/initial-values';
import {
  arrayContains,
  DeclarationFieldName,
  isPrimaryHome,
  isRefinance,
} from 'app/models/fields/conditionals';
import { getREOMonthlyExpenseAmount, getREOMonthlyRentAmount } from 'app/util/formulas';
import { borrowerSsnRef, coBorrowerSsnRef } from 'app/components/FormFields/Ssn/CollectSsnBase';
import { toNormalizedSsn } from 'app/util/normalizers';
import {
  ApplicationSource,
  Ethnicity,
  EthnicityHispanic,
  IncomeSource,
  LiabilityType,
  Race,
  RaceAsian,
  RaceIslander,
  Sex,
  SubjectProperty,
} from '@lenderful/domain';

const toStringifyArray = (value: any[]) => {
  if (!value) { return undefined; }
  return value.map((dependents) => {return dependents.dependentsAges;}).join(', ');
};

// @TODO: Docstrings and partial type removal
const toBinaryFlag = (value: string | boolean): Binary => {
  if (typeof value === 'boolean') {
    return value ? 1 : 0;
  }
  return value === YesOrNo.YES ? 1 : 0;
};

export const toBinaryFlagOrUndefined = (value: string | boolean): Binary | undefined => {
  if (typeof value === 'undefined') {
    return undefined;
  }
  return toBinaryFlag(value);
};

export const toSqlDate = (value: string) => {
  return value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : undefined;
};

export const mapAssets = (form, formName?: FormName): Partial<Asset>[] => {

  // Reverse Mortgage only has two places for borrower assets
  if(formName === FormName.REVERSE) {
    const assets = [];
    if (form[Fields.assetTotalValue]) {
      assets.push({
        asset_amount          : form[Fields.assetTotalValue],
        asset_institution_name: form[Fields.accountFinancialInstitution],
        asset_type            : AccountType.OTHER,
      });
    }
    return assets;
  }

  const assets: Partial<Asset>[] = [];
  if (Array.isArray(form[Fields.assets])) {
    form[Fields.assets].forEach((asset) => {
      assets.push({
        asset_amount          : asset[Fields.accountBalance],
        asset_institution_name: asset[Fields.accountFinancialInstitution],
        asset_type            : asset[Fields.accountType],
      });
    });
  }
  return assets;
};

export const mapCoBorrowerAssets = (form, formName?: FormName): Partial<Asset>[] => {

  // Reverse Mortgage only has one place for coborrower assets
  if(formName === FormName.REVERSE) {
    return [{
      asset_amount          : form[Fields.coBorrowerAssetTotalValue],
      asset_institution_name: form[Fields.accountFinancialInstitution],
      asset_type            : AccountType.OTHER,
    }];
  }
  return [];
};

export const mapIncome = (form, formName?: FormName): Income[] => {

  // Reverse Mortgage only has one place for income
  if(formName === FormName.REVERSE) {
    return [{
      income_type   : IncomeSource.BASE,
      monthly_amount: form[Fields.incomeMonthly],
    }];
  }

  const income: any[] = [];
  if (Array.isArray(form[Fields.additionalIncome])) {
    form[Fields.additionalIncome].forEach((source) => {
      income.push({
        income_type   : source[Fields.incomeSource],
        monthly_amount: source[Fields.incomeMonthly],
      });
    });
  }

  if (Array.isArray(form[Fields.retirementIncome])) {
    form[Fields.retirementIncome].forEach((source) => {
      income.push({
        income_type   : source[Fields.incomeSource],
        monthly_amount: source[Fields.incomeMonthly],
      });
    });
  }

  if (Array.isArray(form[Fields.otherIncome])) {
    form[Fields.otherIncome].forEach((source) => {
      income.push({
        income_type   : source[Fields.incomeSource],
        monthly_amount: source[Fields.incomeMonthly],
      });
    });
  }

  const incomeSources: Income[] = [
    {
      income_type   : IncomeSource.BASE,
      monthly_amount: form[Fields.employBase],
    },
    {
      income_type   : IncomeSource.OVERTIME,
      monthly_amount: form[Fields.employOvertime],
    },
    {
      income_type   : IncomeSource.COMMISSION,
      monthly_amount: form[Fields.employCommission],
    },
    {
      income_type   : IncomeSource.BONUS,
      monthly_amount: form[Fields.employBonus],
    },
  ].concat(income);

  return incomeSources.filter((source) => source.monthly_amount);
};

export const mapReoProperty = (form): ReoProperty[] => {
  const addlProperties: ReoProperty[] = [];
  if (Array.isArray(form[Fields.addlProperties])) {
    form[Fields.addlProperties].forEach((property) => {
      addlProperties.push({
        property_type               : property[Fields.propertyType],
        property_use                : property[Fields.propertyUsage],
        street_address              : property[FieldNames.propertyStreet],
        street_address_2            : property[FieldNames.propertyStreet2],
        city                        : property[FieldNames.propertyCity],
        state                       : property[FieldNames.propertyState],
        postal_code                 : property[FieldNames.propertyZip],
        present_value               : property[FieldNames.propertyValue],
        monthly_expense_amount      : getREOMonthlyExpenseAmount(property),
        monthly_gross_rental_income : getREOMonthlyRentAmount(property),
      });
    });
  }
  return addlProperties;
};

export const mapCoBorrowerIncome = (form, formName?: FormName): Income[] => {

  // Reverse Mortgage only has one income, which is base
  if(formName === FormName.REVERSE) {
    return [{
      income_type   : IncomeSource.BASE,
      monthly_amount: form[Fields.coBorrowerIncomeMonthly],
    }];
  }

  const income: any[] = [];
  if (Array.isArray(form[Fields.coBorrowerAdditionalIncome])) {
    form[Fields.coBorrowerAdditionalIncome].forEach((source) => {
      income.push({
        income_type   : source[Fields.incomeSource],
        monthly_amount: source[Fields.incomeMonthly],
      });
    });
  }

  if (Array.isArray(form[Fields.coBorrowerRetirementIncome])) {
    form[Fields.coBorrowerRetirementIncome].forEach((source) => {
      income.push({
        income_type   : source[Fields.incomeSource],
        monthly_amount: source[Fields.incomeMonthly],
      });
    });
  }

  if (Array.isArray(form[Fields.coBorrowerOtherIncome])) {
    form[Fields.coBorrowerOtherIncome].forEach((source) => {
      income.push({
        income_type   : source[Fields.incomeSource],
        monthly_amount: source[Fields.incomeMonthly],
      });
    });
  }

  const incomeSources: Income[] = [
    {
      income_type   : IncomeSource.BASE,
      monthly_amount: form[Fields.coBorrowerEmployBase],
    },
    {
      income_type   : IncomeSource.OVERTIME,
      monthly_amount: form[Fields.coBorrowerEmployOvertime],
    },
    {
      income_type   : IncomeSource.COMMISSION,
      monthly_amount: form[Fields.coBorrowerEmployCommission],
    },
    {
      income_type   : IncomeSource.BONUS,
      monthly_amount: form[Fields.coBorrowerEmployBonus],
    },
  ].concat(income);

  return incomeSources.filter((source) => source.monthly_amount);
};

export const mapLiabilities = (form): Partial<Liability>[] => {
  const liabilities: any[] = [];
  if (Array.isArray(form[Fields.realEstateLiabilities])) {
    form[Fields.realEstateLiabilities].forEach((liability) => {
      liabilities.push({
        liability_status : liability[Fields.liabilityStatus],
        liability_type   : LiabilityType.MORTGAGE,
        monthly_amount   : liability[Fields.liabilityAmount],
      });
    });
  }
  return liabilities.filter((liability) => liability.monthly_amount);
};

const mapBorrowerResidences = (form): Residence[] => {
  const residences = [mapBorrowerResidence(form)];
  if (form[Fields.livingPrevPropertyStreet]) {
    residences.push(mapBorrowerPreviousResidence(form));
  }
  if (form[Fields.mailingStreet]) {
    residences.push(mapBorrowerMailingResidence(form));
  }
  return residences;
};

const mapBorrowerResidence = (form): Residence => {
  // for refinance of primary home, residence is subject property
  if (isRefinance(form) && isPrimaryHome(form)) {
    return {
      annual_insurance_amount    : form[Fields.yearInsure],
      annual_tax_amount          : form[Fields.yearTaxes],
      association_annual_amount  : form[Fields.yearAssociate],
      city                       : form[Fields.propertyCity],
      current_residence_flag     : toBinaryFlag(YesOrNo.YES),
      duration_months            : moment().diff(moment(form[Fields.livingOccupancyStart]), 'months'),
      escro_flag                 : toBinaryFlag(form[Fields.escrowUsage]),
      postal_code                : form[Fields.propertyZip],
      residence_detail_type       : ResidenceDetailType.CURRENT,
      ownership_type             : LivingSituation.OWN,
      state                      : form[Fields.propertyState],
      street_address_1           : form[Fields.propertyStreet],
      street_address_2           : form[Fields.propertyStreet2],
    };
  }
  return {
    annual_insurance_amount    : form[Fields.livingYearInsure],
    annual_tax_amount          : form[Fields.livingYearTaxes],
    association_annual_amount  : form[Fields.livingYearAssociate],
    city                       : form[Fields.livingPropertyCity],
    current_residence_flag     : toBinaryFlag(YesOrNo.YES),
    duration_months            : moment().diff(moment(form[Fields.livingOccupancyStart]), 'months'),
    escro_flag                 : toBinaryFlag(form[Fields.livingEscrowUsage]),
    monthly_amount             : form[Fields.livingMonthlyMortgage] || form[Fields.livingMonthlyRentAmount],
    postal_code                : form[Fields.livingPropertyZip],
    residence_detail_type       : ResidenceDetailType.CURRENT,
    ownership_type             : form[Fields.livingRentOrOwn],
    state                      : form[Fields.livingPropertyState],
    street_address_1           : form[Fields.livingPropertyStreet],
    street_address_2           : form[Fields.livingPropertyStreet2],
  };
};

const mapBorrowerPreviousResidence = (form): Residence => {
  return {
    city                     : form[Fields.livingPrevPropertyCity],
    current_residence_flag   : toBinaryFlag(YesOrNo.NO),
    duration_months          : moment(form[Fields.livingOccupancyStart]).diff(moment(form[Fields.livingPrevOccupancyStart]), 'months'),
    postal_code              : form[Fields.livingPrevPropertyZip],
    ownership_type           : form[Fields.livingPrevRentOrOwn],
    residence_detail_type     : ResidenceDetailType.PREVIOUS,
    state                    : form[Fields.livingPrevPropertyState],
    street_address_1         : form[Fields.livingPrevPropertyStreet],
    street_address_2         : form[Fields.livingPrevPropertyStreet2],
  };
};

const mapCoBorrowerResidence = (form): Residence => {
  const borrowerResidence: Residence = mapBorrowerResidence(form);

  // these fields don't apply for a co-borrower living at same address as borrower
  const residenceFieldsThatDoNotApply = [
    'annual_insurance_amount',
    'annual_tax_amount',
    'association_annual_amount',
    'monthly_amount',
  ];
  residenceFieldsThatDoNotApply.forEach(field => {
    delete borrowerResidence[field];
  });

  const coBorrowerResidence: Residence = {
    city                     : form[Fields.coBorrowerLivingPropertyCity],
    current_residence_flag   : toBinaryFlag(YesOrNo.YES),
    monthly_amount           : form[Fields.coBorrowerLivingMonthlyRentAmount],
    postal_code              : form[Fields.coBorrowerLivingPropertyZip],
    ownership_type           : form[Fields.coBorrowerLivingRentOrOwn],
    residence_detail_type     : ResidenceDetailType.CURRENT,
    state                    : form[Fields.coBorrowerLivingPropertyState],
    street_address_1         : form[Fields.coBorrowerLivingPropertyStreet],
    street_address_2         : form[Fields.coBorrowerLivingPropertyStreet2],
  };
  return form[Fields.coBorrowerSameAddress] === YesOrNo.YES ? borrowerResidence : coBorrowerResidence;
};

const mapBorrowerMailingResidence = (form): Residence => {
  return {
    city                     : form[Fields.mailingCity],
    current_residence_flag   : toBinaryFlag(YesOrNo.YES),
    duration_months          : undefined,
    postal_code              : form[Fields.mailingZip],
    ownership_type           : undefined,
    residence_detail_type    : ResidenceDetailType.MAILING,
    state                    : form[Fields.mailingState],
    street_address_1         : form[Fields.mailingStreet],
    street_address_2         : form[Fields.mailingStreet2],
  };
};

const mapEmployment = (form): Employment[] => {
  const employment: Employment[] = [];

  if (form[FieldNames.employCompanyName]) {
    employment.push({
      city                   : form[Fields.employCity],
      country                : 'US',
      current_employeer_flag : toBinaryFlag(true),
      employer_name          : form[Fields.employCompanyName],
      employment_type        : form[Fields.employmentType],
      job_start_date         : toSqlDate(form[Fields.employStart]),
      line_of_work_months    : form[Fields.hasLineOfWorkQuestion] ? parseInt(form[FieldNames.lineOfWorkMonths]) || 0 : undefined,
      line_of_work_years     : form[Fields.hasLineOfWorkQuestion] ? parseInt(form[FieldNames.lineOfWorkYears]) || 0 : undefined,
      ownership_share        : form[Fields.employOwnershipShare],
      party_to_transaction   : toBinaryFlag(form[Fields.employPartyTransaction]),
      phone                  : form[Fields.employPhone],
      postal_code            : form[Fields.employZip],
      job_title              : form[Fields.employTitle],
      state                  : form[Fields.employState],
      street_address_1       : form[Fields.employStreet],
      street_address_2       : form[Fields.employStreet2],
    });
  }

  if (form[FieldNames.employPrevName]) {
    employment.push({
      city                   : form[Fields.employPrevCity],
      country                : 'US',
      current_employeer_flag : toBinaryFlag(false),
      employer_name          : form[Fields.employPrevName],
      employment_type        : form[Fields.employmentType],
      job_end_date           : toSqlDate(form[Fields.employPrevEnd]),
      job_start_date         : toSqlDate(form[Fields.employPrevStart]),
      job_title              : form[Fields.employPrevTitle],
      postal_code            : form[Fields.employPrevZip],
      state                  : form[Fields.employPrevState],
      street_address_1       : form[Fields.employPrevStreet],
      street_address_2       : form[Fields.employPrevStreet2],
    });
  }
  return employment;
};

export const mapDeclarationExplanation = (form, borrowerType: DeclarationFieldName): DeclarationExplanation[] => {
  const explanations: DeclarationExplanation[] = [];
  if (form[borrowerType].hasBankrupt) {
    explanations.push({
      declaration_type: DeclarationType.BANKRUPT,
      explanation: `CHAPTER ${ form[borrowerType][FieldNames.bankruptType] } discharged ${ form[borrowerType][FieldNames.bankruptAge] } years ago`,
    });
  }
  if (form[borrowerType].hasForeclosed) {
    explanations.push({
      declaration_type: DeclarationType.FORECLOSED,
      explanation: `Was completed on ${ moment(form[borrowerType][FieldNames.forecloseCompleted]).format('LL') }`,
    });
  }
  if (form[borrowerType].hasBorrowedDown) {
    explanations.push({
      declaration_type: DeclarationType.BORROWED_DOWN_PAYMENT,
      explanation: `Borrowed down payment amount $${ form[borrowerType][FieldNames.borrowedDownAmount] }`,
    });
  }
  return explanations;

};

export const mapCoBorrowerEmployment = (form): Employment[] => {
  const employment: Employment[] = [];

  if (form[Fields.coBorrowerEmployCompanyName]) {
    employment.push({
      city                   : form[Fields.coBorrowerEmployCity],
      country                : 'US',
      current_employeer_flag : toBinaryFlag(true),
      employer_name          : form[Fields.coBorrowerEmployCompanyName],
      employment_type        : form[Fields.coBorrowerEmploymentType],
      job_title              : form[Fields.coBorrowerEmployTitle],
      job_start_date         : toSqlDate(form[Fields.coBorrowerEmployStart]),
      line_of_work_months    : form[Fields.hasLineOfWorkQuestion] ? parseInt(form[FieldNames.lineOfWorkMonths]) || 0 : undefined,
      line_of_work_years     : form[Fields.hasLineOfWorkQuestion] ? parseInt(form[FieldNames.lineOfWorkYears]) || 0 : undefined,
      ownership_share        : form[Fields.coBorrowerEmployOwnershipShare],
      party_to_transaction   : toBinaryFlag(form[Fields.coBorrowerEmployPartyTransaction]),
      phone                  : form[Fields.coBorrowerEmployPhone],
      postal_code            : form[Fields.coBorrowerEmployZip],
      state                  : form[Fields.coBorrowerEmployState],
      street_address_1       : form[Fields.coBorrowerEmployStreet],
      street_address_2       : form[Fields.coBorrowerEmployStreet2],
    });
  }

  if (form[Fields.coBorrowerEmployPrevName]) {
    employment.push({
      city                   : form[Fields.coBorrowerEmployPrevCity],
      country                : 'US',
      current_employeer_flag : toBinaryFlag(false),
      employer_name          : form[Fields.coBorrowerEmployPrevName],
      employment_type        : form[Fields.coBorrowerEmploymentType],
      job_end_date           : toSqlDate(form[Fields.coBorrowerEmployPrevEnd]),
      job_start_date         : toSqlDate(form[Fields.coBorrowerEmployPrevStart]),
      job_title              : form[Fields.coBorrowerEmployPrevTitle],
      postal_code            : form[Fields.coBorrowerEmployPrevZip],
      state                  : form[Fields.coBorrowerEmployPrevState],
      street_address_1       : form[Fields.coBorrowerEmployPrevStreet],
      street_address_2       : form[Fields.coBorrowerEmployPrevStreet2],
    });
  }
  return employment;
};

export const mapEthnicity = (form, borrowerType: DeclarationFieldName): Partial<Borrower> => {
  const hasEthnicity                 = (value) => toBinaryFlag(arrayContains(form[borrowerType][Fields.ethnicity], value));
  const hasHispanicEthnicity         = (value) => toBinaryFlag(arrayContains(form[borrowerType][Fields.ethnicityHispanic], value));
  return {
    ethnicity_hispanic_cuban_flag       : hasHispanicEthnicity(EthnicityHispanic.CUBAN),
    ethnicity_hispanic_flag             : hasEthnicity(Ethnicity.HISPANIC),
    ethnicity_hispanic_mexican_flag     : hasHispanicEthnicity(EthnicityHispanic.MEXICAN),
    ethnicity_hispanic_other            : form[borrowerType][Fields.ethnicityOther],
    ethnicity_hispanic_other_flag       : hasHispanicEthnicity(EthnicityHispanic.OTHER),
    ethnicity_hispanic_puerto_rican_flag: hasHispanicEthnicity(EthnicityHispanic.PUERTO),
    ethnicity_not_hispanic_flag         : hasEthnicity(Ethnicity.NOHISPANIC),
    ethnicity_not_provided_flag         : hasEthnicity(Ethnicity.NOSHARE),
  };
};

export const mapRace = (form, borrowerType: DeclarationFieldName): Partial<Borrower> => {
  const hasRace         = (value) => toBinaryFlag(arrayContains(form[borrowerType][Fields.race], value));
  const hasAsianRace    = (value) => toBinaryFlag(arrayContains(form[borrowerType][Fields.raceAsian], value));
  const hasIslanderRace = (value) => toBinaryFlag(arrayContains(form[borrowerType][Fields.raceIslander], value));
  return {
    race_asian_chinese_flag           : hasAsianRace(RaceAsian.CHINESE),
    race_asian_filipino_flag          : hasAsianRace(RaceAsian.FILIPINO),
    race_asian_flag                   : hasRace(Race.ASIAN),
    race_asian_indian_flag            : hasAsianRace(RaceAsian.INDIAN),
    race_asian_japanese_flag          : hasAsianRace(RaceAsian.JAPANESE),
    race_asian_korean_flag            : hasAsianRace(RaceAsian.KOREAN),
    race_asian_other                  : form[borrowerType][Fields.raceAsianOther],
    race_asian_other_flag             : hasAsianRace(RaceAsian.OTHER),
    race_asian_vietnamese_flag        : hasAsianRace(RaceAsian.VIET),
    race_black_flag                   : hasRace(Race.AFRICAN),
    race_islander_flag                : hasRace(Race.ISLANDER),
    race_islander_guamanian_flag      : hasIslanderRace(RaceIslander.GUAM),
    race_islander_native_hawaiian_flag: hasIslanderRace(RaceIslander.NATIVE),
    race_islander_other               : form[borrowerType][Fields.raceIslanderOther],
    race_islander_other_flag          : hasIslanderRace(RaceIslander.OTHER),
    race_islander_samoan_flag         : hasIslanderRace(RaceIslander.SAMOAN),
    race_native_flag                  : hasRace(Race.NATIVE),
    race_not_provided_flag            : hasRace(Race.NOSHARE),
    race_white_flag                   : hasRace(Race.WHITE),
  };
};

/**
 * This looks at the values in the form and determines whether the borrower or co-borrower is a
 * citizen or not.
 *
 * If the user has answered the citizenship question it will use that answer.  Otherwise, it will
 * default to use the answer provided to the declaration question.
 *
 * @param form
 * @param {BorrowerFieldName} borrowerFieldName borrower / co-borrower
 * @returns {Binary}
 */
export const mapCitizenship = (form, borrowerFieldName: BorrowerFieldName): Binary | undefined => {
  if (borrowerFieldName === FieldNames.decBorrower && form[FieldNames.citizenshipType]) {
    return toBinaryFlag(form[FieldNames.citizenshipType] === CitizenshipType.US_CITIZEN);
  }
  if (borrowerFieldName === FieldNames.decCoBorrower && form[FieldNames.coBorrowerCitizenshipType]) {
    return toBinaryFlag(form[FieldNames.coBorrowerCitizenshipType] === CitizenshipType.US_CITIZEN);
  }
  return toBinaryFlagOrUndefined(form[borrowerFieldName][FieldNames.hasCitizenship]);
};

/**
 * This looks at the values in the form and determines whether the borrower or co-borrower is a
 * permanent US resident or not.
 *
 * If the user has answered the citizenship question it will use that answer.  Otherwise, it will
 * default to use the answer provided to the declaration question.
 *
 * @param form
 * @param {BorrowerFieldName} borrowerFieldName borrower / co-borrower
 * @returns {Binary}
 */
const mapResidentAlien = (form, borrowerFieldName: BorrowerFieldName): Binary => {
  if (borrowerFieldName === FieldNames.decBorrower && form[FieldNames.citizenshipType]) {
    return toBinaryFlag(form[FieldNames.citizenshipType] === CitizenshipType.PERM_RESIDENT);
  }
  if (borrowerFieldName === FieldNames.decCoBorrower && form[FieldNames.coBorrowerCitizenshipType]) {
    return toBinaryFlag(form[FieldNames.coBorrowerCitizenshipType] === CitizenshipType.PERM_RESIDENT);
  }
  return toBinaryFlag(form[borrowerFieldName][FieldNames.hasGreenCard]);
};

export const mapBorrowers = (form): Partial<Borrower>[] => {
  const borrowers: Partial<Borrower>[]     = [];
  const primaryType                        = FieldNames.decBorrower;
  const primaryBorrower: Partial<Borrower> = {
    birth_date                      : toSqlDate(form[Fields.dateOfBirth]),
    credit_score                    : form[Fields.creditScore],
    CURRENT_ASSET                   : mapAssets(form),
    CURRENT_INCOME                  : mapIncome(form),
    CURRENT_LIABILITY               : mapLiabilities(form),
    RESIDENCES                      : mapBorrowerResidences(form),
    REO_PROPERTY                    : mapReoProperty(form),
    credit_report_reference         : form[Fields.creditReportId],
    dec_alimony_childsupport_flag   : toBinaryFlag(form[primaryType][Fields.hasAlimony]),
    dec_bankruptcy_flag             : toBinaryFlag(form[primaryType][Fields.hasBankrupt]),
    dec_borrowed_downpayment_flag   : toBinaryFlag(form[primaryType][Fields.hasBorrowedDown]),
    dec_citizenship_flag            : mapCitizenship(form, primaryType),
    dec_comaker_endorser_flag       : toBinaryFlag(form[primaryType][Fields.hasNoteEndorsement]),
    dec_conveyed_title_flag         : toBinaryFlag(form[primaryType][Fields.hasConveyedTitle]),
    dec_family_relationship_flag    : toBinaryFlag(form[primaryType][Fields.hasFamilyRelationship]),
    dec_home_owner_occupy_flag      : toBinaryFlag(form[primaryType][Fields.hasOccupyPrimary]),
    dec_home_owner_three_years_flag : toBinaryFlag(form[primaryType][Fields.hasOwnerInterest]),
    dec_loan_obligation_flag        : toBinaryFlag(form[primaryType][Fields.hasLoanObligation]),
    dec_mortgage_in_progress_flag   : toBinaryFlag(form[primaryType][Fields.hasAnotherMortgageInProgress]),
    dec_other_lien_flag             : toBinaryFlag(form[primaryType][Fields.hasOtherLienOnProperty]),
    dec_outstanding_judgements_flag : toBinaryFlag(form[primaryType][Fields.hasJudgements]),
    dec_party_to_lawsuit_flag       : toBinaryFlag(form[primaryType][Fields.hasLawsuit]),
    dec_presently_delinquent_flag   : toBinaryFlag(form[primaryType][Fields.hasDelinquent]),
    dec_prior_property_usage_type   : form[primaryType][Fields.ownerPropertyUsage],
    dec_prior_property_title_type   : form[primaryType][Fields.ownerTitleHeld],
    dec_property_foreclosed_flag    : toBinaryFlag(form[primaryType][Fields.hasForeclosed]),
    dec_resident_alien_flag         : mapResidentAlien(form, primaryType),
    dec_short_sale_flag             : toBinaryFlag(form[primaryType][Fields.hasShortSale]),
    dependent_count                 : form[Fields.dependentsAges] ? form[Fields.dependentsAges].length : form[Fields.numOfDependent],
    dependents_age                  : toStringifyArray(form[Fields.dependentsAges]),
    email                           : form[Fields.email],
    EMPLOYMENT                      : mapEmployment(form),
    first_name                      : safelyTrim(form[Fields.firstName]),
    income_on_decline_flag          : toBinaryFlag(form[Fields.incomeOnDeclineYN]),
    last_name                       : safelyTrim(form[Fields.lastName]),
    marital_status                  : form[Fields.maritalStatus],
    middle_name                     : safelyTrim(form[Fields.middleName]),
    military_experience             : form[Fields.militaryExperience],
    name_suffix                     : form[Fields.suffixName],
    phone                           : form[Fields.phone],
    primary_borrower_flag           : toBinaryFlag(true),
    DECLARATION_EXPLANATION         : mapDeclarationExplanation(form, primaryType),
    sex_female_flag                 : toBinaryFlag(arrayContains(form[primaryType][Fields.sex], Sex.FEMALE)),
    sex_male_flag                   : toBinaryFlag(arrayContains(form[primaryType][Fields.sex], Sex.MALE)),
    sex_not_provided_flag           : toBinaryFlag(arrayContains(form[primaryType][Fields.sex], Sex.NOSHARE)),
    ssn                             : borrowerSsnRef ? toNormalizedSsn(borrowerSsnRef.value) : undefined,
    ...mapRace(form, primaryType),
    ...mapEthnicity(form, primaryType),
  };
  borrowers.push(primaryBorrower);

  if (form[FieldNames.coBorrowerYN] === YesOrNo.YES) {
    const coType = FieldNames.decCoBorrower;
    /* Check if declarations exist */
    if (!form[coType]) {
      /* Assign defaults to the co-borrower if they do not exist. (currently not collected on auto prequal) */
      form[coType] = declarationInitialValue;
    }
    const coBorrower: Partial<Borrower> | any = {
      birth_date                      : toSqlDate(form[Fields.coBorrowerDOB]),
      first_name                      : safelyTrim(form[Fields.coBorrowerFirstName]),
      last_name                       : safelyTrim(form[Fields.coBorrowerLastName]),
      middle_name                     : safelyTrim(form[Fields.coBorrowerMiddleName]),
      name_suffix                     : form[Fields.coBorrowerSuffixName],
      email                           : form[Fields.coBorrowerEmail],
      CURRENT_INCOME                  : mapCoBorrowerIncome(form),
      EMPLOYMENT                      : mapCoBorrowerEmployment(form),
      RESIDENCES                      : [mapCoBorrowerResidence(form)],
      primary_borrower_flag           : toBinaryFlag(false),
      credit_report_reference         : form[Fields.creditReportId],
      dec_alimony_childsupport_flag   : toBinaryFlag(form[coType][Fields.hasAlimony]),
      dec_bankruptcy_flag             : toBinaryFlag(form[coType][Fields.hasBankrupt]),
      dec_borrowed_downpayment_flag   : toBinaryFlag(form[coType][Fields.hasBorrowedDown]),
      dec_citizenship_flag            : mapCitizenship(form, coType),
      dec_comaker_endorser_flag       : toBinaryFlag(form[coType][Fields.hasNoteEndorsement]),
      dec_conveyed_title_flag         : toBinaryFlag(form[coType][Fields.hasConveyedTitle]),
      dec_family_relationship_flag    : toBinaryFlag(form[coType][Fields.hasFamilyRelationship]),
      dec_home_owner_occupy_flag      : toBinaryFlag(form[coType][Fields.hasOccupyPrimary]),
      dec_home_owner_three_years_flag : toBinaryFlag(form[coType][Fields.hasOwnerInterest]),
      dec_loan_obligation_flag        : toBinaryFlag(form[coType][Fields.hasLoanObligation]),
      dec_mortgage_in_progress_flag   : toBinaryFlag(form[coType][Fields.hasAnotherMortgageInProgress]),
      dec_other_lien_flag             : toBinaryFlag(form[coType][Fields.hasOtherLienOnProperty]),
      dec_outstanding_judgements_flag : toBinaryFlag(form[coType][Fields.hasJudgements]),
      dec_party_to_lawsuit_flag       : toBinaryFlag(form[coType][Fields.hasLawsuit]),
      dec_presently_delinquent_flag   : toBinaryFlag(form[coType][Fields.hasDelinquent]),
      dec_prior_property_title_type   : form[coType][Fields.ownerTitleHeld],
      dec_prior_property_usage_type   : form[coType][Fields.ownerPropertyUsage],
      dec_property_foreclosed_flag    : toBinaryFlag(form[coType][Fields.hasForeclosed]),
      dec_resident_alien_flag         : mapResidentAlien(form, coType),
      dec_short_sale_flag             : toBinaryFlag(form[coType][Fields.hasShortSale]),
      income_on_decline_flag          : toBinaryFlag(form[Fields.coBorrowerIncomeOnDeclineYN]),
      marital_status                  : form[Fields.coBorrowerMaritalStatus],
      military_experience             : form[Fields.coBorrowerMilitaryExperience],
      phone                           : form[Fields.coBorrowerPhone],
      DECLARATION_EXPLANATION         : mapDeclarationExplanation(form, coType),
      sex_female_flag                 : toBinaryFlag(arrayContains(form[coType][Fields.sex], Sex.FEMALE)),
      sex_male_flag                   : toBinaryFlag(arrayContains(form[coType][Fields.sex], Sex.MALE)),
      sex_not_provided_flag           : toBinaryFlag(arrayContains(form[coType][Fields.sex], Sex.NOSHARE)),
      ssn                             : coBorrowerSsnRef ? toNormalizedSsn(coBorrowerSsnRef.value) : undefined,
      ...mapRace(form, coType),
      ...mapEthnicity(form, coType),
    };
    borrowers.push(coBorrower);
  }
  return borrowers;
};

const mapConstruction = (form): Partial<Construction> => {
  return  {
    loan_type: form[Fields.constructionLoanType],
    land_cost: form[Fields.constructionLandCost],
  };
};

export const mapSubjectProperty = (form): SubjectProperty => {
  return {
    annual_insurance_amount      : form[Fields.yearInsure],
    annual_tax_amount            : form[Fields.yearTaxes],
    association_annual_amount    : form[Fields.yearAssociate],
    built_year                   : form[Fields.builtYear],
    building_units               : form[Fields.buildingUnits],
    city                         : form[Fields.propertyCity],
    construction_cost            : form[Fields.constructionCost],
    construction_home_type       : form[Fields.constructionHomeType],
    construction_roofing_type    : form[Fields.constructionRoofingType],
    construction_land_acquired   : form[Fields.constructionLandAcquired],
    construction_land_owner_flag : toBinaryFlag(form[Fields.constructionLandOwner]),
    county                       : form[Fields.propertyCounty],
    escro_flag                   : toBinaryFlag(form[Fields.escrowUsage]),
    estimated_amount             : form[Fields.homeValue],
    home_stories                 : form[Fields.homeStories],
    living_area                  : form[Fields.livingArea],
    monthly_gross_rental_income  : form[Fields.propertyMonthlyRentalIncome],
    original_cost                : form[Fields.propertyOriginalCost],
    parcel_id                    : form[Fields.parcelId],
    postal_code                  : form[Fields.propertyZip],
    property_acquired_year       : form[Fields.acquiredYear],
    property_type                : form[Fields.propertyType],
    property_type_desc           : form[Fields.propertyTypeDesc],
    property_use                 : form[Fields.propertyUsage],
    purchase_amount              : form[Fields.homeValue],
    state                        : form[Fields.propertyState],
    street_address_1             : form[Fields.propertyStreet],
    street_address_2             : form[Fields.propertyStreet2],
    unpaid_balance_amount        : form[Fields.mortgageBalance],
  };
};

// TODO: loanPurpose is refinance loan purpose and is being translated to either 'CASHOUT' or 'REFINANCE' for refinance applications.
//       This gets translated into a single character 'H', 'P' or 'R' ('H' for 'HELOC' which means 'C' for 'CASHOUT' never happens)
const mapLoanPurpose = (loanPurpose: string) => {
  if (loanPurpose === LoanPurpose.PERSONAL_LOAN) {
    return LoanPurpose.PERSONAL_LOAN;
  }
  if (loanPurpose === LoanPurpose.HELOC) {
    return LoanPurpose.HELOC;
  } else if (loanPurpose === LoanPurpose.CONSTRUCTION) {
    return LoanPurpose.CONSTRUCTION;
  } else if (loanPurpose === LoanPurpose.LAND_PURCHASE) {
    return LoanPurpose.LAND_PURCHASE;
  }
  return loanPurpose === LoanPurpose.PURCHASE ? 'PURCHASE' : 'REFINANCE';
};

/**
 * This maps the appointment date and time from our form to
 * an object to pass to the backend.  Either date or time must
 * be filled out to be considered requesting an appointment.
 *
 * @param form
 * @returns {Appointment}
 */
export const mapAppointment = (form): Appointment => {
  const borrowerDeclaration = form[Fields.decBorrower];
  if (borrowerDeclaration) {
    // Must have date or time filled out to be considered requesting an appointment
    if (borrowerDeclaration[Fields.scheduleDate] || borrowerDeclaration[Fields.scheduleTime]) {
      return {
        date: borrowerDeclaration[Fields.scheduleDate],
        time: borrowerDeclaration[Fields.scheduleTime],
      };
    }
  }
};

/**
 * Maps the current long form application.  Used for Digital Mortgage
 * and Home Equity products.
 *
 * @param form
 * @returns {Partial<LoanApplication> | any}
 *
 * @deprecated work towards using mapUniversalLoanApplication()
 */
export const mapLoanApplication = (form): Partial<LoanApplication> | any => {
  return {
    BORROWER                    : mapBorrowers(form),
    coborrower_on_loan_flag     : toBinaryFlag(form[Fields.coBorrowerYN]),
    coborrower_on_title_flag    : toBinaryFlag(form[Fields.coBorrowerOnTitle]),
    CONSTRUCTION                : mapConstruction(form),
    down_payment_amount         : form[Fields.downDollar],
    loan_purpose                : mapLoanPurpose(form[Fields.loanPurpose]),
    refi_purpose                : mapLongFormRefinancePurpose(form),
    refi_purpose_other          : form[Fields.homeEquityLoanPurposeOther],
    plan_sell_flag              : toBinaryFlag(form[Fields.homeSoldYN]),
    requested_loan_amount       : getLoanAmount(form),
    SUBJECT_PROPERTY            : mapSubjectProperty(form),
    title_first_name            : safelyTrim(form[Fields.firstName]),
    title_last_name             : safelyTrim(form[Fields.lastName]),
    title_middle_name           : safelyTrim(form[Fields.middleName]),
    transfer_reason_code        : form[Fields.transferReasonCode],
    title_suffix                : form[Fields.suffixName],
    fee                         : form[Fields.fee],
    loan_term                   : form[Fields.loanTerm],
    rate                        : form[Fields.rate],
    loan_type                   : form[Fields.loanType],
    product_type                : form[Fields.productType],
    purchase_timeline           : form[Fields.purchaseTimeline],
    expected_closing_date       : form[Fields.expectedClosingDate],
    buying_stage                : form[Fields.buyingProcess],
    branch_employee             : form[Fields.branchEmployee],
    branch_number               : form[Fields.branchNumber],
    monthly_payment             : form[Fields.monthlyPayment],
  };
};

export const mapLongFormRefinancePurpose = (form) => {
  if(form[Fields.homeEquityLoanPurpose]) {
    return form[Fields.homeEquityLoanPurpose];
  } else if(
    ![LoanPurpose.LAND_PURCHASE, LoanPurpose.PURCHASE, LoanPurpose.CONSTRUCTION]
      .includes(form[Fields.loanPurpose])) {
    return form[Fields.loanPurpose];
  }
  return undefined;
};

export const mapRequestSource = (formName: FormName): ApplicationSource => {
  if (formName === FormName.AUTOPREQUAL) {
    return ApplicationSource.PRE_APPROVAL;
  }
  return ApplicationSource.MORTGAGE;
};

export const safelyTrim = (value?: string): string => {
  if (value) {
    return value.trim();
  }
  return value;
};
