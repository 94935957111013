import { push, goBack } from 'connected-react-router';
import { Epic, ofType } from 'redux-observable';
import { AnyAction } from 'redux';
import { referToLoBypassEnabled, devToolLog } from 'app/actions';
import * as FormActions from 'app/actions/form/actions';
import { Type } from 'app/actions/form/types';
import { getSectionById } from 'app/routes';
import { getFormValues } from 'redux-form';
import { mapTo, map } from 'rxjs/operators';
import { Section, isSection } from 'app/models/types';
import { Location } from 'history';
import { ReferToLoConditional, TransferToFormConditional } from 'app/models/fields/conditionals';
import { getFormName, getRouteByFormName, sectionFromHash } from 'app/routes/helpers';
import { filterQuestions } from 'app/util/question-helpers';
import { excludedQuestionsSelector } from 'app/reducers/app-config';
import { logger } from 'app/util/logger';
import { returnInitialValues } from '../util/initial-values';
import { setInitialFormValues } from 'app/actions/form/actions';
import { FieldNames } from '../models/fields/names';

export interface GetNextSectionAction extends AnyAction {
  /**
   * An optional array of conditionals to test against
   * to see if a user should be refered to a loan officer
   *
   * @memberof GetNextSectionAction
   */
  conditionalIf?: {
    referToLoIf?: ReferToLoConditional[];
    transferFormIf? : TransferToFormConditional[];
  };

  formValues  : object;
}

export interface RouterProp {
  location: Location;
}

/**
 * Gets the next section id when the section passes any conditionals by incrementing
 * the current section id.
 *
 * If the section does not pass it's conditionals, this function will increment and
 * call itself until finding a section it passes.  This currently checks to see if the
 * section should be shown and if all the questions are exlucluded on a section.
 *
 * @param {*} store Redux Store
 * @param {number} sectionId Form section id
 * @returns {number} Section id
 */
export const getNextSectionId = (store: any, sectionId: number): number => {
  const path              = store.router.location.pathname;
  const nextId            = sectionId + 1;
  const nextSection       = getSectionById(path, nextId);
  const formValues        = getFormValues(getFormName(path))(store);
  const excludedQuestions = excludedQuestionsSelector(store);
  const answeredQuestions = formValues[FieldNames.answeredQuestions];

  if (canSectionBeDisplayed(nextSection as Section, formValues, excludedQuestions, answeredQuestions)) {
    return nextId;
  } else {
    logger.debug(`Skipping section ${nextId}, conditionals not met`);
    return getNextSectionId(store, nextId);
  }
};

/**
 * Pushes the next section to the router
 *
 * @param {*} store Redux store
 */
export const pushNextSectionFromCurrent = (store: any) => {
  const path           = store.router.location.pathname;
  const sectionId      = sectionFromHash(store.router.location.hash);
  const nextId         = getNextSectionId(store, sectionId);
  return push(`${path}#${nextId}`);
};

export const determineNextRoute = (action: GetNextSectionAction, store: any) => {
  if(action.conditionalIf) {
    const { transferFormIf, referToLoIf } = action.conditionalIf;
    if (transferFormIf) {
      const transferToFormReason = transferFormIf.find(({ condition }) => condition(action.formValues));
      logger.debug('Transferring forms...', transferToFormReason);
      if (transferToFormReason) {
        return redirectToNewFrom(transferToFormReason);
      }
    }
    /* If the section has a referToLoIf condition, test it */
    if (referToLoIf) {
      const referToLoReason = referToLoIf.find(({ condition }) => condition(action.formValues));
      if (referToLoReason && !referToLoBypassEnabled()) {
        logger.debug('Referring to loan officer');
        return FormActions.referToLoanOfficer(referToLoReason.code);
      } else if (referToLoReason && referToLoBypassEnabled()) {
        devToolLog('Router detected loan officer bypass, pushing next section');
        return pushNextSectionFromCurrent(store);
      }
      logger.debug('No conditions passed, getting next section');
    }
  }
  /* Otherwise continue to next section */
  return pushNextSectionFromCurrent(store);
};

/**
 * Similar to pushSectionFromCurrent, but this method jumps
 * directly to the id provided.
 *
 * @param {number} id Section id
 * @param {*} store Redux Store
 */
const pushSectionById = (id: number, store: any) => {
  const path = store.router.location.pathname;
  return push(`${path}#${id}`);
};

/**
 * Checks to see if the section has any questions (as they can be excluded).  Evaluations section's
 * showIf conditions against formValues to determine if the section passes conditionals. Passes
 * when no conditionals are present.
 *
 * @param {Section} section
 * @param {object} [formValues={}]
 * @returns {boolean}
 */
const canSectionBeDisplayed = (section: Section, formValues: object = {}, excludedQuestions: string[], answeredQuestions: string[]): boolean => {
  if (isSection(section)) {
    const allExcludedQuestions = [...(excludedQuestions||[]), ...(answeredQuestions||[])];
    const questions = filterQuestions(section.questions, formValues, allExcludedQuestions);
    if (!section.ifNoQuestions && questions && questions.length < 1) {
      return false;
    }
    if (hasConditionals(section)) {
      const conditions = section.showIf || [];
      return conditions.some((condition) => condition(formValues));
    }
  }
  return true;
};

/**
 * Returns true if the section passed contains a key named
 * 'showIf'
 *
 * @param {Section} section Form Section
 * @returns {boolean}
 */
export const hasConditionals = (section: Section): boolean => {
  return section && typeof section.showIf !== 'undefined';
};

// Epic
// -----

export const getNextSectionEpic: Epic<any, any> = (action$, state$) => {
  return action$.pipe(
    ofType<AnyAction, Type>(Type.GET_NEXT_SECTION),
    map((action: GetNextSectionAction) => determineNextRoute(action, state$.value)),
  );
};

export const goToSectionEpic: Epic<any, any> = (action$, state$) => {
  return action$.pipe(
    ofType<AnyAction, Type>(Type.GO_TO_SECTION),
    map((action) => pushSectionById(action.id, state$.value)),
  );
};
// @TODO: Since we have jumping directly to sections, we'll need to
// implement a new method for previous that doesn't involve history back
export const getPrevSectionEpic: Epic<any, any> = (action$) =>
  action$.pipe(
    ofType<AnyAction, Type>(Type.GET_PREVIOUS_SECTION),
    mapTo(goBack()),
  );

export const redirectToNewFrom = (transferToFormReason: TransferToFormConditional) => {
  return (dispatch, getState) => {
    const { answeredQuestions, code, toForm, fromForm, sectionId = '' } = transferToFormReason;
    const state = getState();
    const fromFormData = getFormValues(fromForm)(state);
    const toFormData = getFormValues(toForm)(state) || {};
    const newFormKeys = Object.keys(toFormData);
    let tempData = {};
    if(newFormKeys.length > 0) { // Second time being transferred
      tempData = { ...toFormData, ...fromFormData, transferReasonCode: code };
    } else {// First time being transferred
      tempData = { ...returnInitialValues(toForm), ...fromFormData, transferReasonCode: code   };
    }

    const initialValueDetails   = {
      formName: toForm,
      formData: Object.assign({}, {}, tempData),
    };
    initialValueDetails.formData['answeredQuestions'] = answeredQuestions;
    dispatch(setInitialFormValues(initialValueDetails));
    return dispatch(push(getRouteByFormName(toForm) + (sectionId !== '' ? `#${sectionId}` : '')));
  };
};
