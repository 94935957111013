import { useTranslation } from 'react-i18next';
import { FormControl } from '@material-ui/core';
import { FieldArray } from 'redux-form';
import { requiredArray } from '../../../util/validators';
import React from 'react';
import { DynamicComponentInput, DynamicFormFieldArray, renderDynamicComponent } from './DynamicComponent';
import { FieldNames } from '../../../models/fields/names';
import { DynamicFormFieldArrayType } from '../../../models/options/enums';
import { personalPropertyInitialValue } from '../../../util/initial-values';
import { PERSONAL_PROPERTY_TYPE_OPTIONS } from '../../../models/options/options';

const personalPropertyInputs: DynamicComponentInput[] = [
  {
    fieldName  : FieldNames.financialStatementPersonalType,
    isOptional : false,
    label      : 'Property Type',
    options    : PERSONAL_PROPERTY_TYPE_OPTIONS,
    type       : DynamicFormFieldArrayType.SELECT,
  },
  {
    fieldName  : FieldNames.financialStatementMarketValue,
    isOptional : false,
    label      : 'Market Value',
    type       : DynamicFormFieldArrayType.CURRENCY,
  },
  {
    fieldName  : FieldNames.financialStatementDescription,
    isOptional : true,
    label      : 'Description',
    type       : DynamicFormFieldArrayType.TEXT_INPUT,
  },
];

const personalPropertyProps: DynamicFormFieldArray = {
  addButtonText : 'Account',
  initialValue  : personalPropertyInitialValue,
  inputs        : personalPropertyInputs,
};

export const PersonalPropertiesFFAComponent = ({ ...props }) => {
  const { name, options } = props;
  const { t }    = useTranslation();

  return (
    <FormControl fullWidth>
      <FieldArray
        component = {renderDynamicComponent}
        name      = { name }
        props     = { { dynamicProps: personalPropertyProps, name, t, options } }
        validate  = {[
          requiredArray([]),
        ]}
      />
    </FormControl>
  );
};
