import { SHARED, INCOME_DISCLAIMER } from 'app/models/questions/shared';
import { RadioField } from 'app/components/FormFields/RadioField';
import { PurchaseDownPayment } from 'app/components/FormFields/PurchaseDownPayment';
import { Select } from 'app/components/FormFields/Select';
import { CurrencyAllowZero } from 'app/components/FormFields/CurrencyAllowZero';
import { Address } from 'app/components/FormFields/Address';
import { State } from 'app/components/FormFields/State';
import { ZipCode } from 'app/components/FormFields/ZipCode';
import { Currency } from 'app/components/FormFields/Currency';
import {
  PREQUAL_BUYING_PROCESS_OPTIONS,
  PURCHASE_TIMELINE_OPTIONS,
  YES_OR_NO_OPTIONS,
  EMPLOYMENT_TYPE_OPTIONS,
  COBORROWER_EMPLOYMENT_TYPE_OPTIONS,
  REAL_ESTATE_LIABILITY_OPTIONS,
} from 'app/models/options/options';
import { RetirementIncomeSources } from 'app/components/FormFieldArrays/RetirementIncomeSources';
import { IncomeSources } from 'app/components/FormFieldArrays/IncomeSources';
import { OtherIncomeSources } from 'app/components/FormFieldArrays/OtherIncomeSources';
import { RealEstateAccountsStatus } from 'app/components/FormFieldArrays/RealEstateAccountsStatus';
import { LoanOfficerLanding } from 'app/components/LoanOfficerLanding';
import { PrequalAmount } from 'app/components/PrequalAmount';
import { Question } from 'app/models/types';
import { FieldNames } from 'app/models/fields/names';
import {
  coBorrowerHasDifferentAddress,
  isVeteran,
  isEmployee,
  isSelfEmployed,
  isRetired,
  isEmploymentOther,
  isCoBorrowerEmployee,
  isCoBorrowerSelfEmployed,
  isCoBorrowerRetired,
  isCoBorrowerEmploymentOther,
} from 'app/models/fields/conditionals';

/**
 * Takes in a field name and returns the corresponding auto prequal
 * question
 *
 * @param {string} name Field name
 * @returns Question with matching field name
 */
export const autoPrequalQuestionByName = (name: string): Question => {
  return AUTO_PREQUAL_QUESTIONS.find((question) => {
    return question.name === name;
  }) as Question;
};

/**
 * Takes in an array of field names and returns an array of questions
 *
 * @param {string[]} [names=[]] Field names
 * @returns Questions matching field names
 */
export const autoPrequalQuestionsByName = (names: string[] = []): Question[] => {
  const questions: Question[] = [];
  for (const name of names) {
    questions.push(autoPrequalQuestionByName(name));
  }
  return questions;
};

export const AUTO_PREQUAL_QUESTIONS: Question[] = [
  {
    id: 1001,
    ...SHARED[FieldNames.firstName],
  },
  {
    id: 1002,
    ...SHARED[FieldNames.middleName],
  },
  {
    id: 1003,
    ...SHARED[FieldNames.lastName],
  },
  {
    id: 1004,
    ...SHARED[FieldNames.email],
  },
  {
    id: 1005,
    ...SHARED[FieldNames.phone],
  },
  {
    id: 1006,
    ...SHARED[FieldNames.dateOfBirth],
  },
  {
    id       : 1007,
    component: RadioField,
    name     : FieldNames.buyingProcess,
    options  : PREQUAL_BUYING_PROCESS_OPTIONS,
    title    : 'Where are you in the buying process?',
  },
  {
    id       : 1008,
    component: RadioField,
    name     : FieldNames.purchaseTimeline,
    options  : PURCHASE_TIMELINE_OPTIONS,
    title    : 'What is your purchase timeline?',
  },
  {
    id          : 1009,
    component   : PurchaseDownPayment,
    hideTitle   : true,
    label       : 'Home Value',
    name        : FieldNames.homeValue,
    fieldPopover: true,
    popover     : 'Enter an estimated value here and our calculations will narrow everything down for you.',
  },
  {
    id: 1010,
    ...SHARED[FieldNames.propertyType],
    component   : Select,
    fieldPopover: true,
  },
  {
    id: 1011,
    ...SHARED[FieldNames.propertyUsage],
    component   : Select,
    fieldPopover: true,
  },
  {
    id: 1012,
    ...SHARED[FieldNames.propertyZip],
  },
  {
    id          : 1013,
    component   : CurrencyAllowZero,
    label       : 'Annual Taxes',
    name        : FieldNames.yearTaxes,
    fieldPopover: true,
    popover     : 'The estimated taxes will be calculated based on a percentage of the home purchase amount.',
  },
  {
    id          : 1014,
    component   : CurrencyAllowZero,
    label       : 'Annual Insurance',
    name        : FieldNames.yearInsure,
    fieldPopover: true,
    popover     : 'Annual insurance amounts will be estimated based on the home purchase price.',
  },
  {
    id          : 1015,
    component   : CurrencyAllowZero,
    label       : 'Annual Association Fees',
    name        : FieldNames.yearAssociate,
    isOptional  : true,
    fieldPopover: true,
    popover     : 'If no HOA fees are identified, the estimated amount will be $0.',
  },
  {
    id       : 1016,
    component: Address,
    label    : 'Street Address',
    name     : FieldNames.livingPropertyStreet,
    title    : 'What is your current address?',
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.livingPropertyStreet,
    },
  },
  {
    id        : 1017,
    component : Address,
    isOptional: true,
    label     : 'Street Address 2',
    name      : FieldNames.livingPropertyStreet2,
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.livingPropertyStreet2,
    },
  },
  {
    id       : 1018,
    component: Address,
    label    : 'City',
    name     : FieldNames.livingPropertyCity,
  },
  {
    id       : 1019,
    component: State,
    label    : 'State',
    name     : FieldNames.livingPropertyState,
  },
  {
    id       : 1020,
    component: ZipCode,
    label    : 'Zip Code',
    name     : FieldNames.livingPropertyZip,
  },
  {
    id       : 1021,
    ...SHARED[FieldNames.maritalStatus],
  },
  {
    id       : 1022,
    component: RadioField,
    name     : FieldNames.coBorrowerYN,
    options  : YES_OR_NO_OPTIONS,
    title    : 'Will you have a co-borrower?',
  },
  {
    id: 1023,
    ...SHARED[FieldNames.firstName],
    name: FieldNames.coBorrowerFirstName,
  },
  {
    id: 1024,
    ...SHARED[FieldNames.middleName],
    name: FieldNames.coBorrowerMiddleName,
  },
  {
    id: 1025,
    ...SHARED[FieldNames.lastName],
    name: FieldNames.coBorrowerLastName,
  },
  {
    id: 1026,
    ...SHARED[FieldNames.dateOfBirth],
    name: FieldNames.coBorrowerDOB,
  },
  {
    id       : 1027,
    component: RadioField,
    name     : FieldNames.coBorrowerSameAddress,
    title    : 'Same address as borrower?',
    options  : YES_OR_NO_OPTIONS,
  },
  {
    id       : 1028,
    component: Address,
    label    : 'Street Address',
    name     : FieldNames.coBorrowerLivingPropertyStreet,
    title    : 'Co-borrower\'s current address?',
    showIf   : [coBorrowerHasDifferentAddress],
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.coBorrowerLivingPropertyStreet,
    },
  },
  {
    id        : 1029,
    component : Address,
    isOptional: true,
    label     : 'Street Address 2',
    name      : FieldNames.coBorrowerLivingPropertyStreet2,
    showIf    : [coBorrowerHasDifferentAddress],
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.coBorrowerLivingPropertyStreet2,
    },
  },
  {
    id       : 1030,
    component: Address,
    label    : 'City',
    name     : FieldNames.coBorrowerLivingPropertyCity,
    showIf   : [coBorrowerHasDifferentAddress],
  },
  {
    id       : 1031,
    component: State,
    label    : 'State',
    name     : FieldNames.coBorrowerLivingPropertyState,
    showIf   : [coBorrowerHasDifferentAddress],
  },
  {
    id       : 1032,
    component: ZipCode,
    label    : 'Zip Code',
    name     : FieldNames.coBorrowerLivingPropertyZip,
    showIf   : [coBorrowerHasDifferentAddress],
  },
  {
    id       : 1033,
    component: RadioField,
    title    : 'Are you a veteran or an active-duty service member?',
    name     : FieldNames.veteranYN,
    options  : YES_OR_NO_OPTIONS,
  },
  {
    id       : 1034,
    component: RadioField,
    title    : 'Are you planning to get a VA loan?',
    name     : FieldNames.veteranLoanYN,
    options  : YES_OR_NO_OPTIONS,
    showIf   : [isVeteran],
  },
  {
    id       : 1035,
    component: LoanOfficerLanding,
    name     : FieldNames.loanOfficer,
  },
  {
    id         : 1036,
    component  : RadioField,
    name       : FieldNames.employmentType,
    title      : 'What is your income source?',
    disclaimer : INCOME_DISCLAIMER,
    options    : EMPLOYMENT_TYPE_OPTIONS,
  },
  {
    id       : 1037,
    component: Currency,
    name     : FieldNames.employBase,
    label    : 'Monthly Base Pay',
    showIf   : [isEmployee, isSelfEmployed],
  },
  {
    id          : 1038,
    component   : Currency,
    fieldPopover: true,
    isOptional  : true,
    label       : 'Monthly Overtime',
    name        : FieldNames.employOvertime,
    showIf      : [isEmployee, isSelfEmployed],
    popover     :
      'Overtime can be included in your income if it has been consistent over a two-year period.',
    title: 'Additional income sources?',
  },
  {
    id          : 1039,
    component   : Currency,
    fieldPopover: true,
    isOptional  : true,
    label       : 'Monthly Commission',
    name        : FieldNames.employCommission,
    showIf      : [isEmployee, isSelfEmployed],
    popover     :
      'Commissions can be included in your income if they have been consistent over a two-year period.',
  },
  {
    id          : 1040,
    component   : Currency,
    fieldPopover: true,
    isOptional  : true,
    label       : 'Monthly Bonus',
    name        : FieldNames.employBonus,
    showIf      : [isEmployee, isSelfEmployed],
    popover     :
      'Bonuses can be included in your income if they have been consistent over a two-year period.',
  },
  {
    id          : 1041,
    component   : IncomeSources,
    name        : FieldNames.additionalIncome,
    fieldPopover: true,
    isOptional  : true,
    popover     : 'Income derived from alimony, child support, or separate maintenance payments need not be revealed if the applicant does not want the creditor to consider it in determining the applicant’s creditworthiness.',
    showIf      : [isEmployee, isSelfEmployed],
  },
  {
    id       : 1042,
    component: RetirementIncomeSources,
    name     : FieldNames.retirementIncome,
    showIf   : [isRetired],
  },
  {
    id       : 1043,
    component: OtherIncomeSources,
    name     : FieldNames.otherIncome,
    showIf   : [isEmploymentOther],
  },
  {
    id       : 1044,
    component: RadioField,
    title    : 'Is the income above consistent and verifiable over the past 2 years?',
    name     : FieldNames.incomeVerifiable,
    options  : YES_OR_NO_OPTIONS,
  },
  {
    id       : 1045,
    component: RadioField,
    title    : 'What is your co-borrower’s income source?',
    name     : FieldNames.coBorrowerEmploymentType,
    options  : COBORROWER_EMPLOYMENT_TYPE_OPTIONS,
  },
  {
    id         : 1046,
    component  : Currency,
    name       : FieldNames.coBorrowerEmployBase,
    label      : 'Monthly Base Pay',
    disclaimer : INCOME_DISCLAIMER,
    showIf     : [isCoBorrowerEmployee, isCoBorrowerSelfEmployed],
  },
  {
    id          : 1047,
    component   : Currency,
    fieldPopover: true,
    isOptional  : true,
    label       : 'Monthly Overtime',
    name        : FieldNames.coBorrowerEmployOvertime,
    showIf      : [isCoBorrowerEmployee, isCoBorrowerSelfEmployed],
    popover     :
      'Overtime can be included in your income if it has been consistent over a two-year period.',
    title: 'Additional income sources?',
  },
  {
    id          : 1048,
    component   : Currency,
    fieldPopover: true,
    isOptional  : true,
    label       : 'Monthly Commission',
    name        : FieldNames.coBorrowerEmployCommission,
    showIf      : [isCoBorrowerEmployee, isCoBorrowerSelfEmployed],
    popover     :
      'Commissions can be included in your income if they have been consistent over a two-year period.',
  },
  {
    id          : 1049,
    component   : Currency,
    fieldPopover: true,
    isOptional  : true,
    label       : 'Monthly Bonus',
    name        : FieldNames.coBorrowerEmployBonus,
    showIf      : [isCoBorrowerEmployee, isCoBorrowerSelfEmployed],
    popover     :
      'Bonuses can be included in your income if they have been consistent over a two-year period.',
  },
  {
    id          : 1050,
    component   : IncomeSources,
    name        : FieldNames.coBorrowerAdditionalIncome,
    fieldPopover: true,
    popover     : 'Income derived from alimony, child support, or separate maintenance payments need not be revealed if the applicant does not want the creditor to consider it in determining the applicant’s creditworthiness.',
    showIf      : [isCoBorrowerEmployee, isCoBorrowerSelfEmployed],
  },
  {
    id       : 1051,
    component: RetirementIncomeSources,
    name     : FieldNames.coBorrowerRetirementIncome,
    showIf   : [isCoBorrowerRetired],
  },
  {
    id       : 1052,
    component: OtherIncomeSources,
    name     : FieldNames.coBorrowerOtherIncome,
    showIf   : [isCoBorrowerEmploymentOther],
  },
  {
    id       : 1053,
    component: RadioField,
    title    : 'Is the income above consistent and verifiable over the past 2 years?',
    name     : FieldNames.coBorrowerIncomeVerifiable,
    options  : YES_OR_NO_OPTIONS,
  },
  {
    id       : 1054,
    component: RealEstateAccountsStatus,
    name     : FieldNames.realEstateLiabilities,
    options  : REAL_ESTATE_LIABILITY_OPTIONS,
  },
  {
    id       : 1055,
    component: PrequalAmount,
    name     : FieldNames.prequalAmount,
  },
  {
    id: 1057,
    ...SHARED[FieldNames.suffixName],
  },
  {
    id: 1058,
    ...SHARED[FieldNames.coBorrowerSuffixName],
  },
];

