import { Question } from 'app/models/types';
import { FieldNames } from 'app/models/fields/names';
import {  SHARED } from 'app/models/questions/shared';
import { StocksFFAComponent } from '../../components/FormFieldArrays/Dynamic/Stocks';
import { RetirementAccountsFFAComponent } from '../../components/FormFieldArrays/Dynamic/RetirementAccounts';
import { NotesFFAComponent } from '../../components/FormFieldArrays/Dynamic/Notes';
import { PersonalPropertiesFFAComponent } from '../../components/FormFieldArrays/Dynamic/PersonalProperties';
import { RealEstateFFAComponent } from '../../components/FormFieldArrays/Dynamic/RealEstate';
import { CreditCardsFFAComponent } from '../../components/FormFieldArrays/Dynamic/CreditCards';
import { MortgagesFFAComponent } from '../../components/FormFieldArrays/Dynamic/Mortgages';
import { LoansFFAComponent } from '../../components/FormFieldArrays/Dynamic/Loans';
import { TaxesFFAComponent } from '../../components/FormFieldArrays/Dynamic/Taxes';
import { RadioField } from '../../components/FormFields/RadioField';
import { YES_OR_NO_OPTIONS } from '../options/options';
import {
  hasFSCreditCard,
  hasFSLoan,
  hasFSMortgage,
  hasFSNote,
  hasFSPersonalProperty,
  hasFSRealEstate,
  hasFSRetirementAccount,
  hasFSStock,
  hasFSTax,
} from '../fields/conditionals';
import { FinancialStatementConfirmation } from '../../components/FinancialStatement/FinancialStatementConfirmation';
import { FINANCIAL_STATEMENT_FINAL_PAGE_QUESTION } from '../sections/constants';
import { Questions } from './Questions';
import { Address } from '../../components/FormFields/Address';
import { State } from '../../components/FormFields/State';

export const FINANCIAL_STATEMENT_QUESTIONS: Question[] = [

  {
    id: 1001,
    ...SHARED[FieldNames.firstName],
    title: 'Enter your personal information',
  },
  {
    id: 1002,
    ...SHARED[FieldNames.middleName],
  },
  {
    id: 1003,
    ...SHARED[FieldNames.lastName],
  },
  {
    id: 1004,
    ...SHARED[FieldNames.suffixName],
  },
  {
    id: 1005,
    ...SHARED[FieldNames.mothersMaidenName],
  },
  {
    id: 1006,
    ...SHARED[FieldNames.email],
  },
  {
    id: 1007,
    ...SHARED[FieldNames.phone],
  },
  {
    id: 1008,
    ...SHARED[FieldNames.dateOfBirth],
  },
  {
    id         : 1009,
    component  : StocksFFAComponent,
    name       : FieldNames.financialStatementStocks,
    showIf     : [hasFSStock],
  },
  {
    id         : 1010,
    component  : RetirementAccountsFFAComponent,
    name       : FieldNames.financialStatementRetirementAccounts,
    showIf     : [hasFSRetirementAccount],
  },
  {
    id         : 1011,
    component  : NotesFFAComponent,
    name       : FieldNames.financialStatementNotes,
    showIf     : [hasFSNote],
  },
  {
    id         : 1012,
    component  : PersonalPropertiesFFAComponent,
    name       : FieldNames.financialStatementPersonalProperties,
    showIf     : [hasFSPersonalProperty],
  },
  {
    id         : 1013,
    component  : RealEstateFFAComponent,
    name       : FieldNames.financialStatementRealEstate,
    showIf     : [hasFSRealEstate],
  },
  {
    id         : 1014,
    component  : CreditCardsFFAComponent,
    name       : FieldNames.financialStatementCreditCards,
    showIf     : [hasFSCreditCard],
  },
  {
    id         : 1015,
    component  : MortgagesFFAComponent,
    name       : FieldNames.financialStatementMortgages,
    showIf     : [hasFSMortgage],
  },
  {
    id         : 1016,
    component  : LoansFFAComponent,
    name       : FieldNames.financialStatementLoans,
    showIf     : [hasFSLoan],
  },
  {
    id         : 1017,
    component  : TaxesFFAComponent,
    name       : FieldNames.financialStatementTaxes,
    showIf     : [hasFSTax],
  },
  {
    id        : 1018,
    component : RadioField,
    name      : FieldNames.financialStatementHasStock,
    options   : YES_OR_NO_OPTIONS,
    title     : 'Do you have any Stocks, Bonds or Mutual Funds?',
  },
  {
    id        : 1019,
    component : RadioField,
    name      : FieldNames.financialStatementHasRetirementAccount,
    options   : YES_OR_NO_OPTIONS,
    title     : 'Do you have any Retirement Accounts?',
  },
  {
    id        : 1020,
    component : RadioField,
    name      : FieldNames.financialStatementHasNote,
    options   : YES_OR_NO_OPTIONS,
    title     : 'Do you have any Notes or Contracts Held?',
  },
  {
    id        : 1021,
    component : RadioField,
    name      : FieldNames.financialStatementHasPersonalProperty,
    options   : YES_OR_NO_OPTIONS,
    title     : 'Do you have any Personal Property?',
  },
  {
    id        : 1022,
    component : RadioField,
    name      : FieldNames.financialStatementHasRealEstate,
    options   : YES_OR_NO_OPTIONS,
    title     : 'Do you have any Real Estate?',
  },
  {
    id        : 1023,
    component : RadioField,
    name      : FieldNames.financialStatementHasCreditCard,
    options   : YES_OR_NO_OPTIONS,
    title     : 'Do you have any Credit Cards?',
  },
  {
    id        : 1024,
    component : RadioField,
    name      : FieldNames.financialStatementHasMortgage,
    options   : YES_OR_NO_OPTIONS,
    title     : 'Do you have any Mortgages?',
  },
  {
    id        : 1025,
    component : RadioField,
    name      : FieldNames.financialStatementHasLoan,
    options   : YES_OR_NO_OPTIONS,
    title     : 'Do you have any Loans Payable?',
  },
  {
    id        : 1026,
    component : RadioField,
    name      : FieldNames.financialStatementHasTaxes,
    options   : YES_OR_NO_OPTIONS,
    title     : 'Do you have any Taxes Payable?',
  },
  {
    id           : 1027,
    component    : Address,
    label        : 'Street Address',
    name         : FieldNames.livingPropertyStreet,
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.livingPropertyStreet,
    },
  },
  {
    id           : 1028,
    component    : Address,
    isOptional   : true,
    label        : 'Street Address 2',
    name         : FieldNames.livingPropertyStreet2,
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.livingPropertyStreet2,
    },
  },
  {
    id           : 1029,
    component    : Address,
    label        : 'City',
    name         : FieldNames.livingPropertyCity,
  },
  {
    id           : 1030,
    component    : State,
    label        : 'State',
    name         : FieldNames.livingPropertyState,
  },
  {
    ...SHARED[FieldNames.propertyZip],
    id           : 1031,
    name         : FieldNames.livingPropertyZip,
    hideTitle    : true,
  },
  {
    id          : FINANCIAL_STATEMENT_FINAL_PAGE_QUESTION,
    component   : FinancialStatementConfirmation,
    name        : 'FinalPage',
  },
];

export const financialStatementQuestions = new Questions(FINANCIAL_STATEMENT_QUESTIONS);
